.alerts {
	background-color: #EEE;
	padding: 15px 20px;
	border-radius: 16px;
	h3 {
		margin-top: 10px;
	}
	h4 {
		margin-top: 10px;
	}
	.alert_item {
		width: 100%;
		background-color: #FFF;
		padding: 5px 10px;
		border-radius: 12px;
		margin-top: 5px;
		&:hover {
			cursor: pointer;
			opacity: .7;
		}
		span {
			&:first-child {
				color: #3498db;
				font-weight: bold;
				margin-right: 10px;
			}
			&:last-child {
				margin-left: 10px;
				color: #4b4b4b;
			}
			&.hb {
				color: #2ed573;
			}
			&.soon {
				color: #f1c40f;
			}
		}
	}
	a {
		text-decoration: none;
		color: inherit;
	}
}

.divider {
	width: 100%;
	margin: 20px auto 20px;
	height: 1px;
	background-color: #BBB;
}

.needphotos {
    span {
        background-color: #BBB;
        padding: 2px 5px;
        border-radius: 2px;
        line-height: 20px;
        font-size: 12px;
        margin: 5px;
        display: inline-block;
        &:hover {
            cursor: pointer;
            background-color: #CCC;
        }
    }
}

.filter_data {
	width: 440px;
}

.tasks-list {
	margin-top: 20px;
        width: 100%;
        .tasks-item {
            border-radius: 0px 0px 24px 24px;
            padding: 0px 10px 0px;
            background-color: #f6f6f6;
            display: flex;
            margin-bottom: 15px;
            &:nth-child(odd) {
                margin-bottom: 0;
                border-radius: 24px 24px 0px 0px;
            }
            &:nth-child(even) {
                padding-bottom: 10px;
            }
			&:hover {
				opacity: .8;
				cursor: pointer;
				background-color: #F2F2F2;
			}
            > div {
                width: 100%;
                flex-basis: 100%;
                margin: 10px;
                .tasks-block, .tasks-tasks {
                    &.button-save {
                        background-color: #3498db;
                        span {
                            color: #FFF;
                            cursor: pointer;
                            font-size: 14px;
                            padding: 3px 4px;
                            border-radius: 8px;
                            text-align: center;
                            &:hover {
                                opacity: .8;
                            }
                        }
                    }
                    &.days-good {
                        background-color: #2ecc71;
                    }
                    &.days-normal {
                        background-color: #9ee7bc;
                    }
                    &.days-warning {
                        background-color: #f1c40f;
                    }
                    &.days-error {
                        background-color: #e74c3c;
                    }
                    &.days-hide {
                        background-color: #EEE;
                    }
                    margin-top: 10px;
                    background-color: #FFF;
                    border-radius: 24px;
                    padding: 10px 20px;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: left;
                    &.task-button-end, &.task-button-start, &.task-button-hide {
                        height: 62px;
                        display: flex;
                        align-items: center;
                        margin-bottom: 10px;
                        justify-content: center;
                        flex-direction: row-reverse;
                        color: white;
                        text-shadow: 0 0 2px rgba(0, 0, 0, .3);
                        .btn-start,
                        .btn-hide,
                        .btn-end {
                            background-color: #f3f3f3;
                            box-shadow: 0 0 3px rgba(0, 0, 0, .3);
                            width: 30px;
                            height: 30px;
                            display: inline-flex;
                            padding-top: 2px;
                            align-items: center;
                            justify-content: center;
                            border-radius: 8px;
                            margin-right: 10px;
                      
                            &:hover {
                                cursor: pointer;
                                opacity: .7;
                            }
                      
                            i {
                                font-size: 28px;
                            }
                        }
                      
                        .btn-start {
                            i {
                                color: #2ecc71;
                            }
                        }

                        .btn-hide {
                            i {
                                color: #777;
                            }
                        }
                      
                        .btn-end {
                            i {
                                color: #e74c3c;
                            }
                        }
                      
                        span {
                            font-weight: bold;
                            display: inline-block;
                            margin-right: 20px;
                        }
                    }
                    &.task-button-start {
                        background-color: #2ecc71;
                    }
                    &.task-button-end {
                        background-color: #e74c3c;
                    }
                    &.task-button-hide {
                        background-color: #EEE;
                        color: #888;
                        text-shadow: none;
                    }
                    &:has(.avatar) {
                        padding-left: 70px;
                    }
                    &:last-child {
                        &:has(textarea.tasks-result) {
                            height: calc(100% - 10px);
                        }
                    }
                    label {
                        font-weight: bold;
                        margin-bottom: 5px;
                    }
                    textarea {
                        resize: none;
                        border: 1px solid #EEE;
                        border-radius: 16px;
                        padding: 5px 10px;
                        min-height: 150px;
                        &.tasks-result {
                            height: 100%;
                        }
                        min-height: 150px;
                    }
                    input, select, button {
                        padding: 10px 15px;
                        border-radius: 14px;
                        border: 1px solid #EEE;
                        &.little-select {
                            padding: 2px 5px;
                        }
                    }
                    button {
                        &.submit-time {
                            margin-top: 10px;
                        }
                        &:hover {
                            cursor: pointer;
                            background-color: #DDD;
                        }
                        &.create-task {
                            font-weight: bold;
                            color: #FFF;
                            text-shadow: 0px 0px 2px rgba(0,0,0,1);
                            cursor: not-allowed;
                            &.all-fields {
                                background-color: #2ecc71;
                                cursor: pointer;
                                &:hover {
                                    background-color: #27ae60;
                                }
                            }
                        }
                    }
                    &.countdown {
                        background-color: #e74c3c;
                        color: white;
                        position: relative;
                        width: 250px;
                        margin: 10px auto;
                        > div {
                            margin-top: -5px;
                            font-size: 36px;
                            font-weight: bold;
                            position: absolute;
                        }
                        span {
                            margin-left: 40px;
                        }
                    }
                    ul {
                        &.tasks-peoples {
                            cursor: pointer;
                            li {
                                a {
                                    color: black;
                                    &:hover {
                                        text-decoration-color: #3498db;
                                        color: #3498db;
                                    }
                                    i {
                                        margin-right: 5px;
                                    }
                                }
                                font-weight: 100;
                                text-decoration: underline;
                                &:hover {
                                    text-decoration-color: #3498db;
                                    color: #3498db;
                                }
                            }
                        }
                        li {
                            > .bi {
                                &:hover {
                                    cursor: pointer;
                                }
                                margin-right: 10px;
                            }
                            font-weight: bold;
                            margin-top: 10px;
                        }
                    }
                    .avatar {
                        position: absolute;
                        left: 15px;
                    }
                    span {
                        &:first-child {
                            font-weight: bold;
                        }
                        &:last-child {

                        }
                    }
                    .multi-select, .multi-select-plugin {	
                        display: inline-block;	
                        position: relative;
                        font-weight: 100;
                        label {
                            font-weight: 100;
                        }
                        > span {
                            background: none;
                            position: relative;
                            padding: .25em .5em;
                            padding-right: 1.5em;
                            display: block;
                            border: solid 1px #EEE;
                            border-radius: 14px;
                            cursor: default;
                            padding: 7px 15px;
                            
                            > .chevron {
                                display: inline-block;
                                transform: rotate(-90deg) scale(1, 2) translate(-50%, 0);
                                font-size: 12px;
                                position: absolute;
                                top: 6px;
                                right: 8px;
                                font-weight: bold;
                            }
                        }
                        
                        > ul {
                            position: absolute;
                            font-weight: 100;
                            list-style: none;
                            padding: 0;
                            margin: 0;
                            left: 0;
                            top: 100%;
                            width: 100%;
                            z-index: 1000;
                            background: #fff;
                            border: 1px solid #EEE;
                            border-radius: 14px;
                            box-shadow: 0 6px 12px rgba(0,0,0,.175);
                            display: none;
                            max-height: 320px;
                            overflow-x: hidden;
                            overflow-y: auto;
                            
                            > li {
                                white-space: nowrap;
                                
                                &.selected {
                                    > label {
                                        background-color: #2ecc71;
                                    }
                                }
                                
                                &.focused {
                                    > label {
                                        background-color: #ecf0f1;
                                    }
                                }
                                
                                > label {
                                    padding: 5px 10px;
                                    display: block;
                                    font-weight: 100;
                                    
                                    &:focus, &:hover {
                                        background-color: #ecf0f1;
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                        
                        &.in {
                            > ul {
                                display: block;
                            }
                        }
                        
                        &-backdrop {
                            position: fixed;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            z-index: 900;
                        }
                        > .search-container {
                            margin-top: 5px;
                            input {
                                width: 100%;
                            }
                        }
                    }
                    .selected-items {
                        max-height: 300px;
                        overflow-y: scroll;
                        li {
                            font-weight: 100;
                        }
                    }
                }
                .always-visible {
                    display: flex !important;
                }
          
                .hidden {
                    display: none;
                }
            }
        }
    }