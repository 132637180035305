.error-container {
	width:100%;
	height:100%;
	background:linear-gradient(180deg, #f0f2fd 0%, #fff8f9 100%);
	overflow:hidden;
}
.error-container p {
	margin:20px 0;
	text-align:center;
}
.error-container h1 {
	text-align:center;
	line-height:1.5;
	color:#292968;
}
.error-container h1 span  {
	font-size:18px;
	font-weight:400;
	letter-spacing:.2px;
	display:block;
	color:#70708c;
}
.error-container p  {
	color:#3d3d66;
}
.error-container img {
	height:200px;
	margin:100px auto 80px;
	display:block;
}
.error-container a.btn {
	display:block;
	width:200px;
	padding:15px 0;
	margin:50px auto 0;
	border-radius:100px;
    background:#605bff;
	color:#fff;
	transition:.3s;
	text-decoration:none;
	font-size:110%;
}
.error-container a.btn:hover {
	cursor:pointer;
	background:#2e00d6;
}