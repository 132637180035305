.list-office-mail-documents {
	padding-top:10px;
	margin:0 20px 0 10px;
}
.list-office-mail-documents-item {
	display:flex;
	flex-direction:row;
	align-items:center;
	padding:10px;
	transition:all .5s linear;
	border-bottom:1px solid #eee;
}
.list-office-mail-documents-item:hover {
	background:#f8f8f8;
}
.list-office-mail-documents-item .name {
	color:#70708c;
}
.list-office-mail-documents-item .name span {
	display:block;
	font-weight:600;
	font-size:120%;
	color:#292968;
}
.list-office-mail-documents-item .buttons {
	margin-left:auto;
	display:flex;
	flex-direction:row;
}
.list-office-mail-documents-item .buttons i {
	font-size:140%;
	margin-right:20px;
	transition:.3s;
	color:#605bff;
}
.list-office-mail-documents-item .buttons i:hover {
	color:#2e00d6;
	cursor:pointer;
}
.list-office-mail-documents-item i.delete {color:#c00;}