.imageviewr-container {
	position:fixed;
	top:0;
	right:0;
	bottom:0;
	left:0;
	background:rgba(0,0,0,.6);
	align-content:center;
	align-items:center;
}
.imageviewr-container .imageviewr-image {
	height:100vh;
}
.imageviewr-container .imageviewr-close {
	position:absolute;
	right:20px;
	top:20px;
	font-size:200%;
	color:#aaa;
	transition:.3s;
}
.imageviewr-container .imageviewr-close:hover {
	color:#fff;
	cursor:pointer;
}
.pdf-canvas {
	text-align:center;
}