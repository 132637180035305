.notifications-list {

}
.notifications-list .notfound {
	font-size:120%;
	font-weight:500;
}
.list-notifications {
	padding-top:10px;
	margin:0 20px 0 10px;
}
.list-notifications-item {
	display:flex;
	flex-direction:column;
	align-items:flex-start;
	padding:10px;
	margin: 5px 0;
	transition:all .5s linear;
	border-bottom:1px solid #eee;
	border-radius: 20px;
}
.list-notifications-item:hover {
	background:#f8f8f8;
	cursor: pointer;
}
.list-notifications-item__unread {
	background:#605dff10;
}
.list-notifications-item .name {
	color:#70708c;
}
.list-notifications .name span {
	display:block;
	font-weight:600;
	font-size:120%;
	color:#292968;
}