.note-modal-container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
    background: rgba(0, 0, 0, .2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    .note-modal-content {
        margin: 20px;
        overflow-y: auto;
        position: absolute;
        width: 60%;
        max-height: 100vh;
        background-color: #FFF;
        background-color: #F8F8F8;
        border-radius: 24px;
        padding: 20px 30px;
        .note-modal-header {
            border-bottom: 1px solid #CCC;
            margin-bottom: 20px;
            h3 {
                font-size: 20px;
            }
            i {
                position: absolute;
                top: 10px;
                right: 30px;
                font-size: 20px;
                color: #e74c3c;
                cursor: pointer;
                &:hover {
                    text-shadow: 0px 0px 3px #e74c3c;
                }
            }
        }
        .note-modal-body {
            font-weight: 100;
            > div {
                margin-top: 15px;
                > span {
                    font-weight: bold;
                    display: block;
                    width: 100%;
                }
                input, textarea, select {
                    font-size: 14px;
                    padding: 10px 15px;
                    border: none;
                    border-radius: 16px;
                }

                textarea {
                    width: 100%;
                    resize: none;
                }

                form {
                    height: 300px;
                    overflow-y: auto;
                    background-color: #FFF;
                    padding: 10px 10px;
                    border-radius: 16px;
                    .select-users {
                        label {
                            input {
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
        }
        .note-modal-footer {
            width: 100%;
            .delete {
                font-weight: bold;
                justify-content: center;
                margin: 10px;
                display: flex;
                width: 150px;
                padding: 5px 10px;
                border-radius: 16px;
                border: none;
                position: absolute;
                bottom: 20px;
                left: 20px;
                color: #e74c3c;
                cursor: pointer;
                &:hover {
                    opacity: .8;
                }
                &:active {
                    opacity: .6;
                }
            }
            .buttons {
                width: 50%;
                margin-left: auto;
                display: flex;
                justify-content: space-between;
                button {
                    font-weight: bold;
                    justify-content: center;
                    margin: 10px;
                    display: flex;
                    width: 100%;
                    padding: 15px 20px;
                    border-radius: 16px;
                    border: none;
                    &.save {
                        background-color: #9DC4FF;
                    }
                    &:hover {
                        cursor: pointer;
                        -webkit-filter: brightness(90%);
                        -webkit-transition: all .5s ease;
                        -moz-transition: all .5s ease;
                        -o-transition: all .5s ease;
                        -ms-transition: all .5s ease;
                        transition: all .5s ease;
                    }
                }
            }
        }
    }
}