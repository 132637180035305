#groups-form h4 {
	margin:30px 0 20px 5px;
	color:#292968;
}
#groups-form ul {
	padding:10px 20px;
	columns:2;
	background:#f2f2f2;
	border-radius:5px;
}
#groups-form ul li {
	margin:5px 0;
}