.journal h4 {
	color:#70708c;
    font-size:120%;
    margin-left:5px;
}
.journal .date {
	color:#292968;
	font-weight:600;
}
.list-journal {
	padding-top:10px;
	margin:0 20px 0 10px;
}
.list-journal-item {
	display:flex;
	flex-direction:column;
	align-items:flex-start;
	padding:10px;
	transition:all .5s linear;
	border-bottom:1px solid #eee;
}
.list-journal-item:hover {
	background:#f8f8f8;
}
.list-journal-item .name {
	color:#70708c;
}
.list-journal-item .name span {
	display:block;
	font-weight:600;
	font-size:120%;
	color:#292968;
}
.list-journal-item .images {
	width: 100%;
}
.list-journal-item .images .documents {
	width: max-content;
	display: flex;
	align-items: center;
	height: 50px;
	color: #000;
	font-size: 30px;
	background-color: #EEE;
	margin-top: 5px;
	padding: 0 10px;
	border-radius: 10px;
	cursor: pointer;
}
.list-journal-item .images .documents:hover {
	opacity: 0.7;
}
.list-journal-item .images .documents span {
	font-size: 14px;
	font-weight: 100;
	color: #000;
	margin-left: 5px;
}
.list-journal-item .images img {
	object-fit: cover;
	width: 200px;
	height: 140px;
	margin-left: 10px;
}
.button-deletes {
	opacity: 1;
	border-radius: 8px;
	background-color: #EEE;
	border: none;
	padding: 5px 10px;
}
.button-deletes:hover {
	background-color: #CCC;
}