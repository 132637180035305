.filter {
    width: 100%;
    background-color: #f6f6f6;
    border-radius: 24px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    font-size: 12px;
    padding: 10px 10px;
    > div {
        width: 100%;
        &:last-child {
            width: 40%;
            margin-left: 10px;
            justify-content: space-between;
        }
        &.active {
            background-color: #82E1FF;
        }
        padding: 5px 10px;
        background-color: #FFF;
        display: block;
        border-radius: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:nth-child(1), &:nth-child(2), &:nth-child(3) {
            &:hover {
                cursor: pointer;
                opacity: 0.8;
            }
        }
        &:last-child {
            margin-right: 0;
        }
        input {
            border: none;
            padding-left: 5px;
            width: 100%;
        }
        select {
            border: none;
            border-radius: 16px;
            background-color: #f6f6f6;
            padding: 2px 4px;
        }
    }
}
.textarea-container {
    display: inline-block;
    width: 100%;
    text-align: left;
    position: relative;
    min-height: 50px;
    span {
        font-size: 13px;
        position: absolute;
        top: 20px;
        left: 15px;
    }

    .custom-textarea {
        width: 100%;
        padding: 40px 15px 10px;
        border: none;
        border-radius: 16px;
        resize: none;
        position: relative;
    }
}

.default-input {
    display: inline-block;
    width: 100%;
    padding: 10px 15px;
    border: none;
    border-radius: 24px;
    
    &:disabled {
        background-color: #FFF;
        color: #000;
    }
}

.avatar {
    width: 45px;
    height: 45px;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
    }
    i {
        background-color: #EEE;
    }
}

.tabs {
    padding: 10px 15px;
    width: 100%;
    flex-basis: 100%;
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        li {
            display: inline-block;
            padding: 10px;
            border: none;
            cursor: pointer;
            &:hover {
                opacity: .7;
            }
            &.active {
                text-decoration: underline;
                color: #2980b9;
            }
        }
    }
    margin-bottom: 10px;
}

.parental_list {
    a {
        text-decoration: none;
        color: inherit;
    }
    .parent {
        &:hover {
            cursor: pointer;
            background-color: #EEEEEE;
            .parent_block {
                background-color: #f9f9f9;
            }
        }
    }
}

.parent {
    width: 100%;
    border-radius: 24px;
    padding: 10px 5px 0px;
    flex-wrap: wrap;
    display: flex;
    background-color: #F8F8F8;
    margin-bottom: 15px;
    font-size: 12px;
    a {
        text-decoration: none;
        color: #000;
    }
    
    .parent_block {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: left;
        text-align: left;
        margin: 0 5px;
        margin-bottom: 10px;
        background-color: white;
        border-radius: 24px;
        padding: 5px 24px 10px;
        min-width: 30%;
        &.children {
            padding-left: 55px;
            font-weight: bold;
            min-width: 30%;
            cursor: pointer;
            &:hover {
                opacity: .9;
            }
            .avatar {
                position: absolute;
                left: 7px;
                top: 5px;
            }
            span {
                b {
                    color: #c6c6c6;
                    font-weight: 100;
                }
            }
        }
        &.countdown {
            padding-left: 60px;
            background-color: #e74c3c;
            color: white;
            margin-left: auto;
        }
        .task_countdown {
            left: 20px;
            font-size: 30px;
            position: absolute;
        }
    
        span {
            margin-top: 5px;
            word-break: break-word;
            overflow: hidden;
            line-height: 14px;
            &:first-child {
                font-weight: bold;
            }
            &:last-child {
                font-weight: 100;
            }
            
            &.middle {
                display: flex;
                align-items: center;
                justify-content: left;
                height: 100%;
            }
        }
    }
}

.parental_container {
    background-color: #F8F8F8;
    border-radius: 24px;
    padding-top: 5px;
    height: 100%;
    .parental_tabs {
        padding: 15px 10px;
        .full-width-tabs {
            display: flex;
            width: 100%; 
        }
        
        .tab {
            text-align: center;
            background-color: white;
            border-radius: 24px;
            padding: 10px 20px;
            margin: 0 5px;
            transition: background-color 0.3s ease;
            &:hover {
                cursor: pointer;
                background-color: #c3dbff;
            }
            &.selected {
                background-color: #9DC4FF;
                font-weight: bold;
                &:hover {
                    background-color: #82b4ff;
                }
            }
        }
    }
    .parental_info {
        display: flex;
        font-size: 13px;
        font-weight: bold;
        text-align: left;
        .left_side, .right_side {
            flex-basis: 50%;
            .notes {
                font-weight: 100;
                padding: 15px;
                overflow: scroll;
                height: 100%;
                border-radius: 24px;
                .note {
                    div {
                        margin-top: 10px;
                    }
                    background-color: white;
                    border-radius: 24px;
                    padding: 10px 20px;
                    margin-bottom: 20px;
                    text-align: left;
                    .note_date {
                        font-weight: bold;
                    }
                    .note_name {
                        i {
                            font-style: unset;
                            text-decoration: underline;
                        }
                    }
                }
            }
            .form {
                display: flex;
                flex-wrap: wrap;
                padding-left: 15px;
                .form-section {
                    flex-basis: 100%;
                    padding: 7px;
                    position: relative;
                    .textarea-container {
                        width: 100%;
                    }
                    &.wider {
                        margin-left: auto;
                        flex-basis: 80%;
                    }
                    label {
                        color: #000 !important;
                        display: inline-block;
                        margin-bottom: 5px;
                        width: 30%;
                        text-align: left;
                        font-size: 13px;
                    }
                    
                    input {
                        display: inline-block;
                        width: 70%;
                        padding: 10px 15px;
                        border: none;
                        border-radius: 24px;
                        &:disabled {
                            background-color: #FFF;
                            color: #000;
                        }
                    }
                }
            }
        }
        .right_side {
            & > button {
                margin-left: 40px;
            }
            flex-basis: 50%;
            .photos {
                padding: 15px 20px;
                .photo_block {
                    margin-bottom: 20px;
                    span {
                        width: 100%;
                        display: block;
                        margin-bottom: 5px;
                    }
                    .photo {
                        display: flex;
                        justify-content: left;
                        align-items: center;
                        img {
                            border-radius: 50%;
                            width: 160px;
                            height: 160px;
                            max-width: 100%;
                            max-height: 100%;
                            object-fit: contain;
                            border-radius: 50%;
                        }
                    }
                }
            }
            .form {
                padding: 15px 30px 15px;
                display: flex;
                font-size: 13px;
                width: 100%;
                .left-part,
                .right-part {
                    width: 50%;
                    div {
                        text-align: left;
                    }
                }

                .left-part {
                    padding-top: 10px;
                    text-align: left;
                }

                .right-part {
                    div:has(> i), div:has(> span) {
                        padding-top: 10px;
                    }
                }

                .button {
                    margin-right: 10px;
                    padding: 10px 20px;
                    border: none;
                    width: 150px;
                    background-color: #8CFF79;
                    border-radius: 24px;
                    &:hover {
                        background-color: #7fe86d;
                        cursor: pointer;
                    }
                    &:disabled {
                        background-color: #FFF;
                        color: #000;
                    }
                }

                .checkbox {
                    margin-right: 5px;
                }
            }
            .tasks {
                padding: 10px 20px;
                border-radius: 24px;
                background-color: #FFF;
                width: calc(100% - 60px);
                margin-left: 30px;
                margin-top: 50px;
                ul {
                    margin-top: 15px;
                    li {
                        margin-top: 10px;
                        i {

                        }
                        span {
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
        .tasks_container {
            flex-basis: 100%;
            padding: 0 15px;


            .tasks_list {
                width: 100%;
                margin-top: 20px;
                .task {
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 15px;
                    .task_block {
                        &.wider1 { flex: 0 0 30%; }
                        &.wider2 { flex: 0 0 15%; }
                        flex: 1;
                        background-color: #FFF;
                        padding: 10px 20px;
                        position: relative;
                        text-align: left;
                        margin: 0 15px;
                        margin-bottom: 10px;
                        display: flex;
                        flex-direction: column;
                        border-radius: 24px;
                        span {
                            &:first-child {
                                order: 1;
                            }
                            &:last-child {
                                order: 2;
                                font-weight: 100;
                            }
                        }
                        &:last-child {
                            flex: 0 0 15%;
                            text-align: center;
                            .task_start  {
                                margin: auto;
                            }
                            background-color: #86E99C;
                        }
                    }
                }
            }
        }
    }
}

.default_button {
    border: none;
    padding: 10px 20px;
    border-radius: 24px;
    background-color: white;
    font-weight: bold;
    margin-bottom: 10px;
    &:hover {
        background-color: #EEE;
        cursor: pointer;
    }
    &:active {
        background-color: #CCC;
    }
}
.control-panel {
    position: relative;
    display: flex;
    i {
        font-size: 30px;
        cursor: pointer;
        color: #2ecc71;
    }
}

.program_title {
    color: #a29bfe
}

.info_title {
    color: #636e72
}