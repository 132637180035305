.correction-timetable h4 {
	color:#70708c;
    font-size:120%;
    margin-left:5px;
}
.correction-timetable .date {
	color:#292968;
	font-weight:600;
}
.list-correction-timetable {
	padding-top:10px;
	margin:0 20px 0 10px;
}
.list-correction-timetable-item {
	display:flex;
	flex-direction:row;
	align-items:center;
	padding:10px;
	transition:all .5s linear;
	border-bottom:1px solid #eee;
}
.list-correction-timetable-item:hover {
	background:#f8f8f8;
}
.list-correction-timetable-item .name {
	color:#70708c;
}
.list-correction-timetable-item .name span {
	display:block;
	font-weight:600;
	font-size:120%;
	color:#292968;
}
.list-effects-item {
	flex-direction: column;
	align-items: flex-start;
}