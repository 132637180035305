.list-employee-item {
	display:flex;
	flex-direction:row;
	align-items:center;
	padding:10px;
	border-bottom:1px solid #eee;
}
.list-employee-item:hover {
	background:rgba(0,0,0,.03);
}
.list-employee-item .avatar {
	width:75px;
	height:75px;
	margin-right:20px;
	display:flex;
	justify-content:center;
	align-items:center;
	background:#f0f0f0;
	border-radius:10px;
	overflow:hidden;
}
.list-employee-item .avatar img {
	width:100%;
	height:100%;
	object-fit:cover;
}
.avatar i {
	font-size:70px;
	color:#fff;
}
.list-employee-item .name {
	width:280px;
}
.list-employee-item .name h3, .list-employee-item h4 {
	color:#292968;
	font-weight:400;
}
.list-employee-item .name h3 {
	font-size:140%;
	margin:5px 0;
	color:#3d3d66;
}
.list-employee-item .name h4 {
	font-size:120%;
}
.list-employee-item .name .date {
	margin-top:5px;
	color:#70708c;
}
.list-employee-item .buttons {
	margin-left:auto;
	display:flex;
	flex-direction:row;
}
.list-employee-item .buttons i {
	font-size:140%;
	margin-right:20px;
	transition:.3s;
	color:#605bff;
}
.list-employee-item .buttons i:hover {
	color:#2e00d6;
	cursor:pointer;
}
.list-employee-item .work {
	margin-top:5px;
	align-self:flex-start;
	color:#70708c;
}
.list-employee-item .work span {
	display:block;
	font-weight:600;
}