.list-psychology-parent-item {
	display:flex;
	flex-direction:row;
	align-items:center;
	padding:10px;
	border-bottom:1px solid #eee;
}
.list-psychology-parent-item:hover {
	background:rgba(0,0,0,.03);
}
.list-psychology-parent-item .avatar {
	width:40px;
	height:40px;
	margin-right:20px;
	display:flex;
	justify-content:center;
	align-items:center;
	background:#f0f0f0;
	border-radius:100px;
	overflow:hidden;
}
.list-psychology-parent-item .avatar img {
	width:100%;
	height:100%;
	object-fit:cover;
}
.list-psychology-parent-item .avatar i {
	font-size:30px;
	color:#fff;
}
.list-psychology-parent-item .name {
	color:#292968;
	font-size:120%;
	font-weight:400;
}
.list-psychology-parent-item .controls {
	display:flex;
	flex-direction:row;
	margin-top:2px;
}
.list-psychology-parent-item .controls a {
	text-decoration:none;
	color:#605bff;
	font-size:90%;
	margin:0 10px 0 -5px;
	padding:1px 5px;
}
.list-psychology-parent-item .controls a:last-child {margin-right:0;}
.list-psychology-parent-item .controls a:hover {
	background:#605bff;
	color:#fff;
	border-radius:3px;
}
.list-psychology-parent-item .buttons {
	margin-left:auto;
	display:flex;
	flex-direction:row;
}
.list-psychology-parent-item .buttons i {
	font-size:140%;
	margin-right:20px;
	transition:.3s;
	color:#605bff;
}
.list-psychology-parent-item .buttons i:hover {
	color:#2e00d6;
	cursor:pointer;
}