.timesheet {
	position: fixed;
	width: calc(100vw - 40px);
	height: calc(100vh - 40px);
	top: 20px;
	left: 20px;
	background-color: #FFF;
	box-shadow: 0px 0px 20px rgba(0,0,0,.3);
	> div {
		padding: 20px 40px 40px 20px;
		font-family: Arial, sans-serif;
		overflow: scroll;
		font-size: 11px;
		position: relative;
		overflow-x: scroll;
		overflow-y: scroll;
		pointer-events: all;
		height: 100%;
		width: 100%;

		&::-webkit-scrollbar {
			width: 20px;
			cursor: pointer;
			height: 20px;
		}
		&::-webkit-scrollbar-track {
			background-color: #f1f1f1;
		}
		
		&::-webkit-scrollbar-thumb {
			background-color: #888;
			border-radius: 5px;
		}
		
		&::-webkit-scrollbar-thumb:hover {
			background-color: #555;
		}

		.timesheet-loader {
			position: absolute;
			width: 100vw;
			height: 100vh;
			background-color: rgba(0,0,0,.1);
			position: fixed;
			top: 0;
			left: 0;
			z-index: 100;
			display: flex;
			justify-content: center;
			align-items: center;
			> div {
				height: 100px;
				img {
					width: 100px;
					height: 100px;
				}
			}
		}

		.date-picker {
			font-size: 14px;
			font-weight: none;
			select, input {
				margin: 0px 15px;
				padding: 5px 10px;
				border: 1px solid #EEE;
				border-radius: 16px;
			}
		}

		.weekend-cell {
			background-color: #ffe7e7;
		}
		
		table {
			border-collapse: collapse;
			width: 100%;
			border: 1px solid #ccc;
		}

		th, td {
			border: 1px solid #ccc;
			padding: 8px;
			width: 100px;
			text-align: center;
			&.current-teacher {
				font-weight: bold;
			}
		}

		th {
			> i.close-button {
				font-size: 20px;
				color: #c0392b;
				&:hover {
					cursor: pointer;
					color: #e74c3c;
				}
			}
			background-color: #f2f2f2;
		}

		tr:nth-child(odd) {
			background-color: #FFF;
		}

		tr:nth-child(even) {
			background-color: #f2f2f2;
		}
		tr:hover {
			td {
				background-color: #dddddd;
				&.weekend-cell {
					background-color: #e7bfbf;
				}
			}
			background-color: #BBB;
		}

		tr {
			td:hover {
				background-color: #CCC;
				&.weekend-cell {
					background-color: #dca6a6;
				}
			}
		}
		.timesheet-tr {
			input, span {
				cursor: default;
				border: 1px solid #EEE;
				width: 80px;
				display: block;
				background-color: #FFF;
			}
			span {
				display: flex;
				justify-content: space-evenly;
				padding: 5px 0;
				i {
					font-size: 16px;
					&:hover {
						cursor: pointer;
						color: #686de0;
						&:first-child {
							color: #e74c3c;
						}
						&:last-child {
							color: #2ecc71;
						}
					}
				}
			}
			input {
				width: 80px;
				&:last-child {
					border-radius: 0 0 16px 16px;
				}
				&:first-child {
					border-radius: 16px 16px 0 0;
				}
			}
			button {
				width: 80px;
			}
			&.current-teacher {
				background-color: #ffeaa7;
				td {
					&.weekend-cell {
						background-color: #fdcb6e;
					}
				}
				&:hover {
					background-color: #f1d886;
					td {
						&.weekend-cell {
							background-color: #ffc862;
						}
						background-color: #ffe17e;
						&:hover {
							background-color: #f1cd58;
							&.weekend-cell {
								background-color: #f4bc53;
							}
						}
					}
				}
			}
		}
	}
}
.list-hr-teachers-item {
	display:flex;
	flex-direction:row;
	align-items:center;
	padding:10px;
	border-bottom:1px solid #eee;
}
.list-hr-teachers-item:hover {
	background: rgba(0,0,0, .03);
}
.list-hr-teachers-item .avatar {
	width:40px;
	height:40px;
	margin-right:20px;
	display:flex;
	justify-content:center;
	align-items:center;
	background:#f0f0f0;
	border-radius:100px;
	overflow:hidden;
}
.list-hr-teachers-item .avatar img {
	width:100%;
	height:100%;
	object-fit:cover;
}
.list-hr-teachers-item .avatar i {
	font-size:30px;
	color:#fff;
}
.list-hr-teachers-item .name {
	color:#292968;
	font-size:120%;
	font-weight:400;
}
.list-hr-teachers-item .controls {
	display:flex;
	flex-direction:row;
	margin-top:2px;
}
.list-hr-teachers-item .controls a {
	text-decoration:none;
	color:#605bff;
	font-size:90%;
	margin:0 10px 0 -5px;
	padding:1px 5px;
}
.list-hr-teachers-item .controls a:last-child {margin-right:0;}
.list-hr-teachers-item .controls a:hover {
	background:#605bff;
	color:#fff;
	border-radius:3px;
}
.list-hr-teachers-item .buttons {
	margin-left:auto;
	display:flex;
	flex-direction:row;
}
.list-hr-teachers-item .buttons i {
	font-size:140%;
	margin-right:20px;
	transition:.3s;
	color:#605bff;
}
.list-hr-teachers-item .buttons i:hover {
	color:#2e00d6;
	cursor:pointer;
}