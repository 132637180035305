.list-parents {
	padding-top:10px;
	height:500px;
	overflow:auto;
}
.list-parents-inline {
	padding-top:0;
	padding-bottom:10px;
	height:unset;
}
.list-parents-item {
	display:flex;
	flex-direction:row;
	align-items:center;
	padding:10px;
	transition:all .5s linear;
}
.list-parents-item:hover {
	background:#f8f8f8;
}
.list-parents-inline .list-parents-item:hover {
	background:transparent;
}
.list-parents-item .avatar {
	width:50px;
	height:50px;
	margin-right:20px;
	display:flex;
	justify-content:center;
	align-items:center;
	background:#f0f0f0;
	border-radius:10px;
	overflow:hidden;
}
.list-parents-item .avatar img {
	width:100%;
	height:100%;
	object-fit:cover;
}
.list-parents-item .name {
	width:220px;
	color:#70708c;
}
.list-parents-item .name span {
	display:block;
	font-weight:600;
	font-size:120%;
	color:#292968;
}
.list-parents-item .info {
	color:#70708c;
}
.list-parents-item .info span {
	display:block;
	font-weight:600;
	font-size:100%;
	color:#292968;
}
.list-parents-item .buttons {
	margin-left:auto;
	display:flex;
	flex-direction:row;
}
.list-parents-item .buttons i {
	font-size:140%;
	margin-right:20px;
	transition:.3s;
	color:#605bff;
}
.list-parents-item .buttons i:hover {
	color:#2e00d6;
	cursor:pointer;
}
.list-parents-item i.delete {color:#c00;}
.list-documents {
	padding-top:10px;
	margin:0 20px 0 10px;
}
.list-documents-item {
	display:flex;
	flex-direction:row;
	align-items:center;
	padding:10px;
	transition:all .5s linear;
	border-bottom:1px solid #eee;
}
.list-documents-item:hover {
	background:#f8f8f8;
}
.list-documents-item .name {
	width:220px;
	color:#70708c;
}
.list-documents-item .name span {
	display:block;
	font-weight:600;
	font-size:120%;
	color:#292968;
}
.list-documents-item .buttons {
	margin-left:auto;
	display:flex;
	flex-direction:row;
}
.list-documents-item .buttons i {
	font-size:140%;
	margin-right:20px;
	transition:.3s;
	color:#605bff;
}
.list-documents-item .buttons i:hover {
	color:#2e00d6;
	cursor:pointer;
}
.list-documents-item i.delete {color:#c00;}