.archive-export-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.archive-export-row .form-row {
    width: 100%;
}
.archive-export-row .form-row select {
    width: 100%;
}