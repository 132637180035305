.education-journal h4 {
	color:#70708c;
    font-size:120%;
    margin-left:5px;
}
.education-journal .date {
	color:#292968;
	font-weight:600;
}
.list-education-journal {
	padding-top:10px;
	margin:0 20px 0 10px;
}
.list-education-journal-item {
	display:flex;
	flex-direction:row;
	align-items:center;
	padding:10px;
	transition:all .5s linear;
	border-bottom:1px solid #eee;
}
.list-education-journal-item:hover {
	background:#f8f8f8;
}
.buttondelete {
    margin-top: 10px;
    border-radius: 5px;
    background-color: #EEE;
    border: none;
    padding: 5px 10px;
}
.buttondelete:hover {
    background-color: #CCC;
    cursor: pointer;
}
.buttons_rate {
    display: flex;
}
.rate-button {
    background-color: blue;
    background-color: white;
    margin-left: 5px;
    border: 1px solid #EEE;
    padding: 10px 15px;
}
.rate-button:hover {
    background-color: #EEE;
    cursor: pointer;
}
.rate-button .selected {
    background-color: green;
}
.list-education-journal-item .name {
	color:#70708c;
}
.list-education-journal-item .name span {
	display:block;
	font-weight:600;
	font-size:120%;
	color:#292968;
}

.dairy-education-journal {
	display: grid;
	overflow: hidden;
}

.box-education-journal2 {
	display: grid;
	overflow: scroll;
}

.table-education-journal {
	border-collapse: collapse;
	border-spacing: 0;
	border: 1px solid black;
	cursor: default;
}

.table-education-journal td, th {
	border: 1px solid black;
	padding: 5px;
	text-align: center;
	min-width: 30px;
}

td:hover {
	cursor: pointer;
	background-color: #b3c5ff;
}

tr:hover {
	background-color: #e0e7fe;
}

tr:nth-child(even):hover {
	background-color: #e0e7fe;
}

tr:nth-child(even){
	background-color: #e2e2e2;
}

th {
	background-color: white;
}

.table-education-journal__description th:first-child {
	background-color: white;
}

.table-education-journal__month th {
	background-color: white;
	text-transform: capitalize;
}

.table-education-journal__month th {
	text-align: left;
}

.journal-popup__backgroud {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
}

.journal-popup__backgroud:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.39);
    transition: background-color ease 1s;
}

.journal-popup__inner {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 20px;
    padding: 30px;
    overflow: hidden;
}

.journal-popup__inner > .form-row {
    box-shadow: none;
    margin: 0 !important;
}

.journal-popup__inner > .oneline {
    margin: 0 auto !important;;
}

.journal-popup__title {
    cursor: default;
    overflow: hidden;
    padding-bottom: 20px;
    overflow-wrap: break-word;
    font-size: 150%;
    font-weight: 600;
    color: #292968;
}

.journal-edit__content {
    padding: 10px;
    border: 2px solid #e0e7fe;
    border-radius: 5px;
    line-height: 1.1;
    color: #70708c;
    font-size: 90%;
    height: inherit;
}

.journal-popup__button {
    display: flex;
    justify-content: end;
    width: 100%;
    margin-top: 20px;
}

.journal-edit__save:disabled {
    background-color: gray;
}

.journal-edit__save:disabled:hover {
    background-color: gray;
}

.journal-rate {
	padding-top: 15px;
}

.journal-save {
	display: flex;
	justify-content: end;
}

.journal-popup__inner .form-row-block h3::after {
	display: none;
}

.journal-add {
    
}