.chat-container {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    height: 100%;
  
    .chat-header {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
  
        .left-side {
            button {
                background-color: #82E1FF;
                border: none;
                padding: 8px 15px;
                border-radius: 24px;

                &:hover {
                    cursor: pointer;
                }
            }
        }
  
        .right-side {
            margin-left: 180px;
            span:first-child {
                font-size: 20px;
                font-weight: bold;
            }

            span:nth-child(2) {
                margin-left: 10px;;
            }
            
            span:last-child {
                margin-left: 10px;
                color: #999;
            }

            i {
                font-size: 18px;
                margin-left: 10px;
                cursor: pointer;
                &:hover {
                    color: #666;
                }
            }
        }
    }
  
    .chat {
        display: flex;
        flex: 1;
        height: 90%;
        .left-side {
            flex-basis: 25%;
            
            ul {
                list-style-type: none;
                padding: 0;
  
                li {
                    width: calc(100% - 30px);
                    margin: 10px;
                    background-color: #EEE;
                    padding: 10px 15px;
                    border-radius: 24px;
                    font-weight: bold;
                    &:hover {
                        cursor: pointer;
                        background-color: #dcdcdc;
                    }
                    &.active {
                        background-color: #A7FFB5;
                        &:hover {
                            background-color: #99eba6;
                        }
                    }
                }
            }
        }
  
        .right-side {
            &:has(> .select-chat) {
                justify-content: flex-start;
            }
            .select-chat {
                position: relative;
                height: 100%;
                width: 100%;
                span {
                    position: absolute;
                    top: 50%;
                    width: 100%;
                    text-align: center;
                    font-size: 16px;
                    color: #888;
                    cursor: default;
                }
                img {
                    position: absolute;
                    top: calc(50% + 20px);
                    left: calc(50% + 15px);
                    transform: translateX(-50%);
                    margin: 0 auto;
                    text-align: center;
                }
            }
            flex-basis: 75%;
            border-radius: 24px;
            background-color: #f4f4f4;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            height: 90%;
            .input-container {
                padding: 10px 30px 30px;
                width: 100%;
                textarea {
                    width: calc(75% - 10px);
                    resize: none;
                    vertical-align: middle;
                    border: none;
                    border-radius: 24px;
                    padding: 15px 25px;
                    margin-right: 10px;
                }
                button {
                    width: 25%;
                    border-radius: 24px;
                    background-color: #A7FFB5;
                    border: none;
                    padding: 15px 20px;
                }
            }
            .chat-dialog {
                display: flex;
                flex: 1;
                flex-direction: column-reverse;
                align-items: flex-start;
                height: 100%;
                width: 100%;
                padding: 30px;
                overflow-y: scroll;
                .message-date {
                    padding: 5px 10px;
                    font-weight: bold;
                    margin: 0 auto;
                    color: #555;
                    z-index: 1;
                    width: 100%;
                    text-align: center;
                    &::after {
                        content: '';
                        display: block;
                        margin: 0 auto;
                        width: 80%;
                        border-bottom: 2px solid #ddd;
                    }
                }
                .message {
                    display: inline-block;
                    padding: 10px 20px;
                    margin: 4px;
                    border-radius: 24px;
                    width: auto;
                    max-width: 80%;
                    &.sender {
                        background-color: #f5fcff;
                        align-self: flex-start;
                        .message-sender {
                            font-weight: bold;
                            margin-bottom: 5px;
                        }
                        .message-bottom {
                            color: rgb(162, 162, 162);
                            text-align: right;
                            i {
                                color: #2ecc71;
                                margin-left: 5px;
                            }
                        }
                    }
                    
                    &.user {
                        background-color: #FFF;
                        align-self: flex-end;
                        .message-sender {
                            font-weight: bold;
                            margin-bottom: 5px;
                        }
                        .message-bottom {
                            color: rgb(162, 162, 162);
                            text-align: right;
                            i {
                                color: #2ecc71;
                                margin-left: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
}