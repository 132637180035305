.search-container {
    input {
        width: 400px;
        border-radius: 24px;
        padding: 10px 20px;
        border: 1px solid #f6f6f6;
    }
    span {
        font-weight: bold;
        display: block;
        width: 100%;
        margin-top: 15px;
        margin-bottom: 5px;
        text-align: center;
        font-size: 20px;
        &::after {
            content: '';
            display: block;
            width: 90%;
            margin: 0 auto;
            border-bottom: 1px solid #DDD;
        }
    }
    .search-block {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
        div {
            background-color: #f6f6f6;
            border-radius: 16px;
            padding: 5px 10px;
            a {
                color: #000;
                text-decoration: none;
            }
            &:hover {
                background-color: #eeeeee;
            }
        }
    }
}