.medic-export-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.medic-export-row .form-row {
    width: 100%;
}
.medic-export-row .form-row select {
    width: 100%;
}