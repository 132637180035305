html,body,ul,ol,li,dl,dt,dd,p,pre,h1,h2,h3,h4,h5,h6,blockquote,form,fieldset,legend,table,td,th {margin:0;padding:0;}
html,body {width:100%;height:100%}
body {overflow-x:hidden;min-height:100%;background:#fff;color:#000;font:14px/1.5 Arial,'Helvetica Neue',Helvetica,sans-serif;display:flex;flex-direction:column;height:100vh;}
html {scroll-behavior:smooth;}

img {margin:0;border-width:0;padding:0;}
ul {list-style:none;}

* {box-sizing:border-box;outline:none;}
* {touch-action:manipulation;}

h1,h2,h3,h4 {letter-spacing:-.03em;line-height:1.032em;font-weight:700;}
h1 {font-size:36px;}
h2 {font-size:30px;font-weight:700;}
h3 {font-size:24px;}
h4 {font-size:18px;}

#root {display:flex;flex-direction:column;height:100%;}

/* form */

.form {}
.form .form-row {
	display:flex;
	flex-direction:column;
	margin:0 20px 20px 0;
}
.form .form-row-oneline {
	display:flex;
	flex-direction:row;
}
.form .form-row-block {
	background:#fff;
	padding:20px;
	border-radius:10px;
	box-shadow:0 0 5px rgba(0,0,0,.1);
	margin-left:2px;
}
.form .form-row-block-inner {
	margin-top:10px;
}
.form .form-row label {
	margin:0 0 3px 5px;
	text-transform:lowercase;
	color:#70708c;
}
.form .form-row label.normal {
	text-transform:capitalize;
	margin:0;
}
.form .form-row input, .form .form-row select, .form .form-row textarea {
	width:300px;
	background:#fff;
	border:2px solid #e0e7fe;
    border-radius:5px;
    padding:10px;
    color:#70708c;
	font-weight:600;
	font-size:105%;
}
.form .form-row input::placeholder, .form .form-row textarea::placeholder {font-weight:400;}
.form .form-row input.date {padding:8px 15px;width:250px;}
.form .form-row input.file {padding:7px 10px;}
.form .form-row input:focus, .form .form-row select:focus, .form .form-row textarea:focus {
	border-color:#605bff;
}
.form .form-row input:invalid, .form .form-row select:invalid, .form .form-row textarea:invalid {
	border-color:#c00;
}
.form .form-row select.lite {width:100px;}
.form .form-row select.middle {width:150px;}
.form .form-row textarea {
	width:100%;
	height:80px;
	resize:none;
}
.form .form-row textarea.large {height:200px;}
.form .form-row textarea.middle {height:150px;}
.form .form-row input[type=radio], .form .form-row input[type=checkbox] {
	width:auto;
}
.form .form-row input.double {width:400px;}
.form .form-row input.wide {width:100%;}
.form .form-row input.full {
	width:auto;
}
.form .form-photo {
	width:150px;
	height:150px;
	margin-right:30px;
	display:flex;
	justify-content:center;
	align-items:center;
	background:#f0f0f0;
	border-radius:20px;
	margin-bottom:30px;
	overflow:hidden;
	border:2px solid #f0f0f0;
}
.form .form-photo-error {
	border-color:#c00;
}
.form .form-photo i {
	font-size:150px;
	color:#fff;
}
.form .form-photo img {
	width:100%;
	height:100%;
	object-fit:cover;
}
.form h3 {
	color:#70708c;
	font-size:120%;
	margin:0;
	cursor:pointer;
	position:relative;
}
.form h3::after {
	content:'▾';
	position:absolute;
	right:0;
	top:0;
	font-size:150%;
}
.form h3.nocllick {
	cursor:default;
}
.form h3.nocllick::after {
	content:'';
}
.form .btn {
	height:36px;
	border:none;
	display:inline-block;
	padding:0 15px;
	border-radius:5px;
	background:#ff8f6b;
	font-size:100%;
	font-weight:600;
	text-transform:capitalize;
	color:#fff;
	transition:all .5s linear;
	text-decoration:none;
	display:flex;
	justify-content:space-between;
	align-items:center;
}
.form .btn:hover {
	cursor:pointer;
	background:#605bff;
}

/* control */

.form-control-panel {
	display:flex;
	align-items:center;
}
.form-control-panel button, .form-control-panel a.btn {
	height:36px;
	border:none;
	display:inline-block;
	padding:0 15px;
	border-radius:24px;
	background:#EEE;
	font-size:100%;
	font-weight:600;
	text-transform:capitalize;
	color:#000;
	transition:all .1s linear;
	text-decoration:none;
	display:flex;
	justify-content:space-between;
	align-items:center;
}
.form-control-panel button:hover, .form-control-panel a.btn:hover {
	color:#fff;
	cursor:pointer;
	background:#605bff;
	transition:all .1s linear;
}
.form-control-panel a.close {
	text-decoration:none;
	color:#70708c;
	margin-left:20px;
}

/* modal */

.modal-container {
	overflow:hidden;
	border-radius:5px;
}
.modal-container .modal-title {
	padding:20px 40px;
	background:#f8f8f8;
	overflow:hidden;
	display:flex;
	justify-content:space-between;
	align-items:center;
}
.modal-container .modal-title h2 {
	font-size:150%;
	font-weight:600;
	color:#292968;
}
.modal-container .content {
	height:605px;
	padding:20px 40px 0;
	overflow:auto;
}
.modal-container .content input[type=search] {
	width:100%;
	background:#fff;
	border:2px solid #e0e7fe;
    border-radius:5px;
    padding:10px;
    color:#70708c;
}

/* top tabs */

.top-tabs {
	width:100%;
	margin:0 0 20px 0;
	display:flex;
	flex-direction:row;
	align-items:center;
}
.top-tabs li {
	padding:6px 20px;
	border-radius:5px;
	font-size:100%;
	font-weight:600;
	color:#70708c;
	cursor:pointer;
}
.top-tabs li:hover {
	color:#605bff;
}
.top-tabs li.selected {
	color:#fff;
	background:#70708c;
}

/* scroll bar */

::-webkit-scrollbar {background:transparent;width:6px;height:6px;position:absolute;}
::-webkit-scrollbar-track {background:transparent;position:absolute;z-index:-2;}
/* ::-webkit-scrollbar-thumb {border-radius:3px;background:rgba(24,23,22,.14);}
::-webkit-scrollbar-thumb:hover {background:rgba(24,23,22,.6);} */
::-webkit-scrollbar-thumb {border-radius:3px;background-color:#dfe6e9 !important}
::-webkit-scrollbar-thumb:hover {background:#b2bec3 !important;cursor: pointer;}
::-moz-scrollbar {background-color: #dfe6e9;}  
::-moz-scrollbar-thumb {background-color: #b2bec3;}
/* others */

.hide {display:none;}
.block {display:block;}
.control-panel {margin-left: 20px;}
.control-panel button, .control-panel a button {padding: 8px 12px;border: none;border-radius: 24px;background: #82E1FF;cursor: pointer;}
.control-panel button:hover {opacity:.8;}
.control-panel button:active{opacity:.6;}
.control-panel i { font-size: 30px; cursor: pointer; color: #2ecc71 }