.training-container {
    .courses {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 15px;
        .course {
            width: 100%;
            padding: 10px;
            border: none;
            border-radius: 24px;
            background-color: #F7F7F7;
            padding: 15px 20px;
            label {
                font-weight: bold;
            }
            div {
                margin-top: 10px;
            }
            .footer {
                margin-top: 20px;
                i {
                    text-shadow: 0 0 1px #999;
                    color: #eb2f06;
                    &.selected {
                        color: #44bd32;
                        &:hover {
                            text-shadow: 0 0 3px #44bd32;
                        }
                    }
                    &:hover {
                        cursor: pointer;
                        text-shadow: 0 0 3px #eb2f06;
                    }
                }
            }
        }
    }
}