.container h4 {
	display:flex;
	align-items:center;
	justify-content:space-between;
}
.container h4 span a {
	margin-right:10px;
}
.formcontainer {
	margin-top:20px;
    padding:20px;
    border-radius:4px;
    box-shadow:0 2px 2px rgba(204,197,185,.5);
    background:#fff;
    margin-bottom:20px;
    position:relative;
    z-index:1;
    zoom:1;
    overflow:hidden;
}
.formcontainer form {
	max-width:900px;
}
.formcontainer .cell {
	margin:0 20px 20px 0;
	padding-bottom:20px;
	border-bottom:1px solid #ddd;
}
.formcontainer .cell label span {
	display:block;
	margin:0 0 5px 0;
	color:#777;
}
.formcontainer h4.title {
	margin-bottom:20px;
}
.oneline, .onelinefull, .onelinefulldata {
	display:flex;
	flex-direction:row;
	align-items:center;
}
.onelinefull, .onelinefulldata {
	align-items:flex-start;
	justify-content:space-between;
}
.onelinefull .cell {
	width:33%;
}
.onelinefulldata .cell {
	width:50%;
}
.onelinefull table, .onelinefulldata table {
	width:100%;
}
.onelinefulldata .cell {
	border:none;
}