.archive-list {
	width:870px;
	display:flex;
	flex-wrap:wrap;
	flex-direction:row;
	align-items:center;
}
.archive-list .item {
	position:relative;
	width:270px;
	height:120px;
	display:flex;
	flex-direction:column;
	justify-content:center;
	margin:10px;
	padding:10px 30px;
	background:#fff;
	transition:all .3s ease;
	border:1px solid #f2f2f2;
	border-radius:20px;
	text-decoration:none;
}
.archive-list .item:hover {
	box-shadow:0 5px 20px rgb(0,30,80,.1);
}
.archive-list .item i {
	font-size:36px;
	color:#292968;
	margin-right:15px;
}
.archive-list .item .item-title {
	font-size:140%;
	color:#292968;
	line-height:1.2;
}
.archive-list .item .item-description {
	width:100%;
	line-height:1.1;
	color:#70708c;
	font-size:90%;
	margin-top:10px;
}