.calendar {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(7, minmax(50px, 1fr));
    grid-template-rows: 40px;
    grid-auto-rows: 50px;
    overflow: visible;
    
    &-container {
        width: 60%;
        margin: auto;
        box-shadow: 0 0px 3px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background: #fff;
        max-width: 1200px;
    }
    
    &-header {
        text-align: center;
        padding: 10px 0;
        background: linear-gradient(to bottom, rgb(250, 251, 253) 0%,rgba(255,255,255,0) 100%);
        border-bottom: 1px solid rgba(166, 168, 179, 0.12);
        
        h1 {
            margin: 0;
            font-size: 18px;
        }
        
        p {
            margin: 5px 0 0 0;
            font-size: 13px;
            font-weight: 600;
            color: rgba(#51565d, .4);
        }
        
        button {
            background: 0;
            border: 0;
            padding: 0;
            color: rgba(#51565d, .7);
            cursor: pointer;
            outline: 0;
        }
    }
}

.day {
    border-bottom: 1px solid rgba(166, 168, 179, 0.12);
    border-right: 1px solid rgba(166, 168, 179, 0.12);
    text-align: right;
    padding: 5px 5px;
    letter-spacing: 1px;
    font-size: 12px;
    box-sizing: border-box;
    color: #98a0a6;
    position: relative;
    pointer-events: none;
    z-index: 0;

    &:nth-of-type(7n + 7) { border-right: 0; }
    &:nth-of-type(n + 1):nth-of-type(-n + 7) { grid-row: 2; }
    &:nth-of-type(n + 8):nth-of-type(-n + 14) { grid-row: 3; }
    &:nth-of-type(n + 15):nth-of-type(-n + 21) { grid-row: 4; }
    &:nth-of-type(n + 22):nth-of-type(-n + 28) { grid-row: 5; }
    &:nth-of-type(n + 29):nth-of-type(-n + 35) { grid-row: 6; }
    &:nth-of-type(7n + 1) { grid-column: 1/1; }
    &:nth-of-type(7n + 2) { grid-column: 2/2; }
    &:nth-of-type(7n + 3) { grid-column: 3/3; }
    &:nth-of-type(7n + 4) { grid-column: 4/4; }
    &:nth-of-type(7n + 5) { grid-column: 5/5; }
    &:nth-of-type(7n + 6) { grid-column: 6/6; }
    &:nth-of-type(7n + 7) { grid-column: 7/7; }

    &-name {
        font-size: 12px;
        text-transform: uppercase;
        color: #99a1a7;
        text-align: center;
        border-bottom: 1px solid rgba(166, 168, 179, 0.12);
        line-height: 50px;
        font-weight: 500;
    }

    &--disabled {
        color: rgba(#98a0a6, 0.6);
        background-color: #ffffff;
        background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23f9f9fa' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
        cursor: not-allowed;
    }
    &--current {
        background-color: #eefeff;
        color: #535c68;
        font-weight: bold;
        font-size: 14px;
    }
}

.task--primary:hover .task__detail,
.task--danger:hover .task__detail,
.task--info:hover .task__detail,
.task--warning:hover .task__detail {
    visibility: visible;
}

.duplicate0 {
    width: 30%;
    margin-left: 30px;
}
.duplicate1 {
    width: 30%;
}
.duplicate2 {
    width: 30%;
}
.duplicate3 {
    width: 30%;
}

.task {
    border-left-width: 3px;
    padding: 8px 12px;
    margin: 10px;
    border-left-style: solid;
    font-size: 11px;
    position: relative;
    &--warning {
        border-left-color: #fdb44d;
        grid-column: 4 / span 1;
        grid-row: 3;
        background: #fef0db;
        align-self: end;
        color: darken(#fdb44d, 12%);
        margin-top: 15px;
    }

    &--danger {
        border-left-color: #fa607e;
        grid-column: 2 / span 1;
        grid-row: 6;
        margin-top: 15px;
        background: rgba(#fdc5d0, 0.7);
        align-self: end;
        color: darken(#fa607e, 12%);
    }

    &--info {
        border-left-color: #4786ff;
        grid-column: 1 / span 1;
        grid-row: 4;
        margin-top: 15px;
        background: rgba(#dae7ff, 0.7);
        align-self: end;
        color: darken(#4786ff, 12%);
    }

    &--primary {
        background: #4786ff;
        border: 0;
        border-radius: 4px;
        grid-column: 3 / span 1;
        grid-row: 4;
        align-self: end;
        color: #fff;
        //box-shadow: 0 10px 14px rgba(#4786ff, 0.4);
    }

    &__detail {
        visibility: hidden;
        position: absolute;
        left: 0;
        top: calc(100% + 10px);
        background: #fff;
        border: 1px solid rgba(166, 168, 179, 0.2);
        color: #000;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 8px;
        box-shadow:0 10px 40px rgba(0,0,0,0.08);
        z-index: 2;
        width: 200px;
        transform: translateX(-25%);
        max-height: 200px;

        &:after,
        &:before {
            bottom: 100%;
            left: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }

        &:before {
            border-bottom-color: rgba(166, 168, 179, 0.2);
            border-width: 8px;
            margin-left: -8px;
        }

        &:after {
            border-bottom-color: #fff;
            border-width: 6px;
            margin-left: -6px;
        }

        h2 {
            font-size: 15px;
            margin: 0;
            color: #51565d;
        }

        p {
            margin-top: 4px;
            font-size: 12px;
            margin-bottom: 0;
            font-weight: 500;
            color: rgba(#51565d, .7);
        }
    }
}
.calendar-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: -10px;
  
    .arrow-button {
        height: 30px;
        width: 30px;
        background: none;
        border: none;
        border-radius: 8px;
        font-size: 16px;
        cursor: pointer;
        color: #888;
        background-color: #EEE;
        &:first-child {
            padding-left: -5px;
        }
        &:hover {
            background-color: #CCC;
            color: #777;
        }
    }
  
    h1 {
        margin: 0 1rem;
    }
  
    .year-dropdown {
        margin: 0;
        margin-right: 10px;
        select {
            cursor: pointer;
            width: 100px;
            text-align: center;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border: none;
            font-size: 16px;
            padding: 0.2rem;
            border: 1px solid #CCC;
            border-radius: 16px;
            option {
                padding: 10px 15px;
                width: 60px;
                &:hover {
                    background-color: gainsboro;
                }
            }
        }
    }
}

.news-container {
    margin-top: 20px;
    padding: 20px;
    button {
        background-color: #A6DF9C;
        border: none;
        padding: 10px 20px;
        border-radius: 24px;
        &:hover {
            cursor: pointer;
            background-color: #94c68b;
        }
    }
    > div > h4 {
        width: 100%;
        display: block;
        margin-top: 20px;
        cursor: pointer;
        &:hover {
            color: #555;
        }
        &::after {
            content: '';
            display: block;
            width: 90%;
            border-bottom: 2px solid #EEE;
        }
    }
    .news-item {
        background-color: #f6f6f6;
        border-radius: 24px;
        padding: 15px 20px;
        width: 80%;
        margin: 10px auto;
        .news-header {
            section {
                margin: 0;
                height: 20px;
                padding: 10px 10px;
                display: inline-block;
            }
            span {
                height: 20px;
                vertical-align: top;
                margin-top: 2px;
                margin-left: 10px;
                display: inline-block;
                font-weight: bold;
            }
        }
        .news-name {
            padding: 0px 10px;
            font-weight: bold;
            margin-top: 10px;
        }
        .news-content {
            padding: 0px 10px;
            margin-top: 5px;

        }
        .news-author {
            padding: 0px 10px;
            margin-top: 20px;

        }
    }
}