.list-groups-item {
	display:flex;
	flex-direction:row;
	align-items:center;
	padding:10px;
	border-bottom:1px solid #eee;
}
.list-groups-item:hover {
	background:rgba(0,0,0,.03);
}
.list-groups-item .name {
	color:#70708c;
}
.list-groups-item .name span {
	color:#292968;
	display:block;
	font-size:110%;
	font-weight:600;
}
.list-groups-item .buttons {
	margin-left:auto;
	display:flex;
	flex-direction:row;
}
.list-groups-item .buttons i {
	font-size:140%;
	margin-right:20px;
	transition:.3s;
	color:#605bff;
}
.list-groups-item .buttons i:hover {
	color:#2e00d6;
	cursor:pointer;
}