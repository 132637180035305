.list-archive-teacher-item {
	display:flex;
	flex-direction:row;
	align-items:center;
	padding:10px;
	border-bottom:1px solid #eee;
}
.list-archive-teacher-item:hover {
	background:rgba(0,0,0,.03);
}
.list-archive-teacher-item .avatar {
	width:40px;
	height:40px;
	margin-right:20px;
	display:flex;
	justify-content:center;
	align-items:center;
	background:#f0f0f0;
	border-radius:100px;
	overflow:hidden;
}
.list-archive-teacher-item .avatar img {
	width:100%;
	height:100%;
	object-fit:cover;
}
.list-archive-teacher-item .avatar i {
	font-size:30px;
	color:#fff;
}
.list-archive-teacher-item .name {
	color:#292968;
	font-size:120%;
	font-weight:400;
}
.list-archive-teacher-item .buttons {
	margin-left:auto;
	display:flex;
	flex-direction:row;
}
.list-archive-teacher-item .buttons i {
	font-size:140%;
	margin-right:20px;
	transition:.3s;
	color:#605bff;
}
.list-archive-teacher-item .buttons i:hover {
	color:#2e00d6;
	cursor:pointer;
}