.dg-container table {
	width:100%;
	border-collapse:collapse;
}
.dg-container table thead tr {
	text-align:left;
}
.dg-container table thead th {
	padding:10px;
	white-space:nowrap;
	border-bottom:3px solid #ccc;
	background:#70708c;
	color:#fff;
	position:sticky;
	top:0;
}
.dg-container table tbody:before {
    content:"@";
    display:block;
    line-height:5px;
    text-indent:-99999px;
}
.dg-container table tbody td {
	padding:15px 10px;
}
.dg-container table tbody tr {
	background:#fff;
	border-bottom:5px solid #f4f3ef;
}
.dg-container table td a {
	color:#000;
}
.dg-container td .img {
	width:50px;
}