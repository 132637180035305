.filter {
    width: 100%;
    background-color: #f6f6f6;
    border-radius: 24px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    font-size: 12px;
    padding: 10px 10px;
    > div {
        width: 100%;
        &:last-child {
            width: 40%;
            margin-left: 10px;
            justify-content: space-between;
        }
        &.active {
            background-color: #82E1FF;
        }
        padding: 5px 10px;
        background-color: #FFF;
        display: block;
        border-radius: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:nth-child(1), &:nth-child(2), &:nth-child(3) {
            &:hover {
                cursor: pointer;
                opacity: 0.8;
            }
        }
        &:last-child {
            margin-right: 0;
        }
        input {
            border: none;
            padding-left: 5px;
            width: 100%;
        }
        select {
            border: none;
            border-radius: 16px;
            background-color: #f6f6f6;
            padding: 2px 4px;
        }
    }
}
.need-docs {
    width: calc(100% - 30px);
    border-radius: 16px;
    height: 50px;
    background-color: #e74c3c;
    color: #FFF;
    font-weight: bold;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 15px;
}
.control_panel {
    width: 100%;
    margin-left: 30px;
    display: flex;
    justify-content: space-between;
    a {
        button {
            padding: 8px 12px;
            cursor: pointer;
            border: none;
            border-radius: 24px;
            background: #82E1FF;
            &:hover {
                opacity: .8;
            }
            &:active {
                opacity: .6;
            }
        }
        &:first-child {
            button {
                background: #82E1FF;
            }
        }
        margin-left: 10px;
    }
}

.textarea-container {
    display: inline-block;
    width: 100%;
    text-align: left;
    position: relative;
    min-height: 50px;
    span {
        font-size: 13px;
        position: absolute;
        top: 20px;
        left: 15px;
    }

    .custom-textarea {
        width: 100%;
        padding: 10px 15px 10px;
        border: none;
        border-radius: 16px;
        resize: none;
        position: relative;
    }
}

.default-input {
    display: inline-block;
    width: 100%;
    padding: 10px 15px;
    border: none;
    border-radius: 24px;
}

.avatar {
    width: 38px;
    height: 38px;
    overflow: hidden;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 24px;
    }
    i {
        background-color: #EEE;
    }
}

.tabs {
    padding: 10px 15px;
    width: 100%;
    flex-basis: 100%;
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        li {
            display: inline-block;
            padding: 10px;
            border: none;
            cursor: pointer;
            &:hover {
                opacity: .7;
            }
            &.active {
                text-decoration: underline;
                color: #2980b9;
            }
        }
    }
    margin-bottom: 10px;
}

.pupils_list {
    font-size: 12px;
    a {
        text-decoration: none;
        color: inherit;
    }
    .pupil {
        &:hover {
            cursor: pointer;
            background-color: #EEEEEE;
            .pupil_block {
                background-color: #f9f9f9;
            }
        }
    }
}

.control-panel {
    position: relative;
    display: flex;
    i {
        font-size: 30px;
        cursor: pointer;
        color: #2ecc71;
    }
}

.tasks-container {
    width: 100%;
    background-color: white;
    padding: 10px;
    border-radius: 16px;
    .tasks-list {
        width: 100%;
        .tasks-item {
            border-radius: 0px 0px 24px 24px;
            padding: 0px 10px 0px;
            background-color: #f6f6f6;
            display: flex;
            margin-bottom: 15px;
            &:nth-child(odd) {
                margin-bottom: 0;
                border-radius: 24px 24px 0px 0px;
            }
            &:nth-child(even) {
                padding-bottom: 10px;
            }
            > div {
                width: 100%;
                flex-basis: 100%;
                margin: 10px;
                > a {
                    text-decoration: none;
                }
                .tasks-block-button {
                    .task_link {
                        text-decoration: none;
                    }
                    span {
                        color: gray;
                    }
                }
                .tasks-block, .tasks-tasks {
                    > span {
                        &:last-child {
                            font-weight: 100;
                        }
                    }
                    &.button-save {
                        background-color: #3498db;
                        span {
                            color: #FFF;
                            cursor: pointer;
                            font-size: 14px;
                            padding: 3px 4px;
                            border-radius: 8px;
                            text-align: center;
                            &:hover {
                                opacity: .8;
                            }
                        }
                    }
                    &.days-good {
                        background-color: #2ecc71;
                    }
                    &.days-normal {
                        background-color: #9ee7bc;
                    }
                    &.days-warning {
                        background-color: #f1c40f;
                    }
                    &.days-error {
                        background-color: #e74c3c;
                    }
                    &.days-hide {
                        background-color: #EEE;
                    }
                    margin-top: 10px;
                    background-color: #FFF;
                    border-radius: 24px;
                    padding: 10px 20px;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: left;
                    &.task-button-end, &.task-button-start, &.task-button-hide {
                        display: flex;
                        align-items: center;
                        margin-bottom: 10px;
                        justify-content: center;
                        flex-direction: row-reverse;
                        color: white;
                        text-shadow: 0 0 2px rgba(0, 0, 0, .3);
                        .btn-start,
                        .btn-hide,
                        .btn-end {
                            background-color: #f3f3f3;
                            box-shadow: 0 0 3px rgba(0, 0, 0, .3);
                            width: 30px;
                            height: 30px;
                            display: inline-flex;
                            padding-top: 2px;
                            align-items: center;
                            justify-content: center;
                            border-radius: 8px;
                            margin-right: 10px;
                      
                            &:hover {
                                cursor: pointer;
                                opacity: .7;
                            }
                      
                            i {
                                font-size: 28px;
                            }
                        }
                      
                        .btn-start {
                            i {
                                color: #2ecc71;
                            }
                        }

                        .btn-hide {
                            i {
                                color: #777;
                            }
                        }
                      
                        .btn-end {
                            i {
                                color: #e74c3c;
                            }
                        }
                      
                        span {
                            font-weight: bold;
                            display: inline-block;
                            margin-right: 20px;
                        }
                    }
                    &.task-button-start {
                        background-color: #2ecc71;
                    }
                    &.task-button-end {
                        background-color: #e74c3c;
                    }
                    &.task-button-hide {
                        background-color: #EEE;
                        color: #888;
                        text-shadow: none;
                    }
                    &:has(.avatar) {
                        padding-left: 70px;
                    }
                    &:last-child {
                        &:has(textarea.tasks-result) {
                            height: calc(100% - 10px);
                        }
                    }
                    label {
                        font-weight: bold;
                        margin-bottom: 5px;
                    }
                    textarea {
                        height: 200px;
                        resize: none;
                        border: 1px solid #EEE;
                        border-radius: 16px;
                        padding: 5px 10px;
                        min-height: 150px;
                        &.tasks-result {
                            height: 100%;
                        }
                    }
                    input, select, button {
                        padding: 10px 15px;
                        border-radius: 14px;
                        border: 1px solid #EEE;
                        &.little-select {
                            padding: 2px 5px;
                        }
                    }
                    button {
                        &.submit-time {
                            margin-top: 10px;
                        }
                        &:hover {
                            cursor: pointer;
                            background-color: #DDD;
                        }
                        &.create-task {
                            font-weight: bold;
                            color: #FFF;
                            text-shadow: 0px 0px 2px rgba(0,0,0,1);
                            cursor: not-allowed;
                            &.all-fields {
                                background-color: #2ecc71;
                                cursor: pointer;
                                &:hover {
                                    background-color: #27ae60;
                                }
                            }
                        }
                    }
                    &.countdown {
                        background-color: #e74c3c;
                        color: white;
                        position: relative;
                        width: 250px;
                        margin: 10px auto;
                        > div {
                            margin-top: -5px;
                            font-size: 36px;
                            font-weight: bold;
                            position: absolute;
                        }
                        span {
                            margin-left: 40px;
                        }
                    }
                    ul {
                        &.tasks-peoples {
                            cursor: pointer;
                            li {
                                a {
                                    color: black;
                                    &:hover {
                                        text-decoration-color: #3498db;
                                        color: #3498db;
                                    }
                                    i {
                                        margin-right: 5px;
                                    }
                                }
                                font-weight: 100;
                                text-decoration: underline;
                                &:hover {
                                    text-decoration-color: #3498db;
                                    color: #3498db;
                                }
                            }
                        }
                        li {
                            > .bi {
                                &:hover {
                                    cursor: pointer;
                                }
                                margin-right: 10px;
                            }
                            font-weight: bold;
                            margin-top: 10px;
                        }
                    }
                    .avatar {
                        position: absolute;
                        left: 15px;
                    }
                    span {
                        &:first-child {
                            font-weight: bold;
                        }
                        &:last-child {

                        }
                    }
                    .multi-select, .multi-select-plugin {	
                        display: inline-block;	
                        position: relative;
                        font-weight: 100;
                        label {
                            font-weight: 100;
                        }
                        > span {
                            background: none;
                            position: relative;
                            padding: .25em .5em;
                            padding-right: 1.5em;
                            display: block;
                            border: solid 1px #EEE;
                            border-radius: 14px;
                            cursor: default;
                            padding: 7px 15px;
                            
                            > .chevron {
                                display: inline-block;
                                transform: rotate(-90deg) scale(1, 2) translate(-50%, 0);
                                font-size: 12px;
                                position: absolute;
                                top: 6px;
                                right: 8px;
                                font-weight: bold;
                            }
                        }
                        
                        > ul {
                            position: absolute;
                            font-weight: 100;
                            list-style: none;
                            padding: 0;
                            margin: 0;
                            left: 0;
                            top: 100%;
                            width: 100%;
                            z-index: 1000;
                            background: #fff;
                            border: 1px solid #EEE;
                            border-radius: 14px;
                            box-shadow: 0 6px 12px rgba(0,0,0,.175);
                            display: none;
                            max-height: 320px;
                            overflow-x: hidden;
                            overflow-y: auto;
                            
                            > li {
                                white-space: nowrap;
                                
                                &.selected {
                                    > label {
                                        background-color: #2ecc71;
                                    }
                                }
                                
                                &.focused {
                                    > label {
                                        background-color: #ecf0f1;
                                    }
                                }
                                
                                > label {
                                    padding: 5px 10px;
                                    display: block;
                                    font-weight: 100;
                                    
                                    &:focus, &:hover {
                                        background-color: #ecf0f1;
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                        
                        &.in {
                            > ul {
                                display: block;
                            }
                        }
                        
                        &-backdrop {
                            position: fixed;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            z-index: 900;
                        }
                        > .search-container {
                            margin-top: 5px;
                            input {
                                width: 100%;
                            }
                        }
                    }
                    .selected-items {
                        max-height: 300px;
                        overflow-y: scroll;
                        li {
                            font-weight: 100;
                        }
                    }
                }
                .always-visible {
                    display: flex !important;
                }
          
                .hidden {
                    display: none;
                }
            }
        }
    }
}
.pupil {
    width: 100%;
    border-radius: 24px;
    padding: 10px 5px 0px;
    display: flex;
    flex-wrap: wrap;
    background-color: #F8F8F8;
    margin-bottom: 15px;
    .pupil_block {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: left;
        text-align: left;
        margin: 0 5px;
        margin-bottom: 10px;
        background-color: white;
        border-radius: 24px;
        padding: 0px 14px 5px;
        justify-content: center;
        
        > select {
            border: 1px solid #EEE;
            border-radius: 8px;
            margin-top: 5px;
        }

        &.tasks-done, &.tasks-not-started, &.warning, &.default, &.tasks-error {
            color: #FFF;
            background-color: #2ecc71;
            position: relative;
            width: 250px;
            text-shadow: 0 0 2px rgba(0,0,0,.3);
            > div {
                margin-top: 8px;
                font-size: 30px;
                font-weight: bold;
                position: absolute;
                text-align: center;
                width: 30px;
            }
            span {
                margin-left: 40px;
                color: white !important;
            }
        }
        &.tasks-done {
            background-color: #2ecc71;
            color: #FFF !important;
        }
        &.tasks-not-started {
            background-color: #FFF;
            color: #000 !important;
            text-shadow: none
        }
        &.warning {
            background-color: #f1c40f;
            color: #FFF !important;
        }
        &.tasks-error {
            background-color: #e74c3c;
            color: #FFF !important;
        }
        &.default {
            background-color: #FFF;
            color: #000 !important;
            text-shadow: none;
        }
        &:first-child {
            padding-left: 50px;
            font-weight: bold;
            .avatar {
                position: absolute;
                left: 5px;
                top: 3px;
            }
        }
        &.countdown {
            padding-left: 60px;
            background-color: #e74c3c;
            color: white;
            margin-left: auto;
        }
        .task_countdown {
            left: 20px;
            font-size: 30px;
            position: absolute;
        }
    
        span {
            margin-top: 5px;
            word-break: break-word;
            overflow: hidden;
            line-height: 14px;
            font-size: 13px;
            &:first-child {
                font-weight: bold;
            }
            &:last-child {
                font-weight: 100;
                color: #636e72;
            }
            
            &.middle {
            }
        }
    }
}

.pupil_container {
    background-color: #F8F8F8;
    border-radius: 24px;
    padding-top: 5px;
    .pupil_tabs {
        padding: 15px 10px;
        .full-width-tabs {
            display: flex;
            width: 100%; 
            display: flex;
            flex-wrap: unset;
        }
        
        .tab {
            text-align: center;
            background-color: white;
            border-radius: 24px;
            padding: 10px 20px;
            margin: 0 5px;
            transition: background-color 0.3s ease, text-shadow 0.3s ease;
            justify-content: center;
            display: flex;
            vertical-align: middle;
            align-items: center;
            font-size: 13px;
            &:hover {
                cursor: pointer;
                background-color: #c3dbff;
                color: #FFF;
                text-shadow: 0px 0px 2px rgba(0,0,0,.4);
                transition: background-color 0.3s ease, text-shadow 0.3s ease;
            }
            &.selected {
                background-color: #9DC4FF;
                font-weight: bold;
                color: #FFF;
                text-shadow: 0px 0px 2px rgba(0,0,0,.4);
                transition: background-color 0.3s ease, text-shadow 0.3s ease;
                &:hover {
                    background-color: #82b4ff;
                }
            }
        }
    }
    .pupil_info {
        display: flex;
        font-size: 13px;
        font-weight: bold;
        text-align: left;
        font-size: 14px;
        
        .photos_side {
            width: 100%;
            padding: 15px 20px;
            .photo_note {
                margin-bottom: 20px;
                padding: 20px 15px;
                background-color: #FFF;
                border-radius: 24px;
                .photo_header {
                    margin-bottom: 10px;
                    padding-left: 20px;
                    .photo_comment {
                        font-weight: 100;
                    }
                }
                &:hover {
                    .button-delete {
                        opacity: 1;
                    }
                }
                .photo_block {
                    display: flex;
                    .photo_img {
                        i {
                            position: absolute;
                            top: 5px;
                            right: 5px;
                            background-color: #EEE;
                            border-radius: 4px;
                            font-size: 16px;
                            padding: 4px 6px 0px;
                            cursor: pointer;
                        }
                        width: 240px;
                        height: 160px;
                        img {
                            height: 100%;
                            width: 100%;
                            cursor: pointer;
                            object-fit: contain;
                        }
                        &:hover {
                            transform: scale(120%);
                        }
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .left_side {
            flex-basis: 50%;
            .notes {
                font-weight: 100;
                padding: 15px;
                overflow: scroll;
                height: 460px;
                border-radius: 24px;
                .note {
                    div {
                        margin-top: 10px;
                    }
                    background-color: white;
                    border-radius: 24px;
                    padding: 10px 20px;
                    margin-bottom: 20px;
                    text-align: left;
                    .note_date {
                        font-weight: bold;
                    }
                    .note_name {
                        i {
                            font-style: unset;
                            text-decoration: underline;
                        }
                    }
                }
            }
            .form {
                display: flex;
                flex-wrap: wrap;
                padding-left: 15px;
                .form-section {
                    flex-basis: 100%;
                    padding: 7px;
                    position: relative;
                    &.text-top {
                        label {
                            vertical-align: top;
                            margin-top: 15px;
                        }
                    }

                    a {
                        color: #000;
                    }

                    .parent-block {
                        background-color: #FFF;
                        width: 70%;
                        display: inline-block;
                        font-weight: normal;
                        padding: 10px 15px;
                        border-radius: 24px;
                        &:hover {
                            svg {
                                filter: invert(14%) sepia(9%) saturate(21%) hue-rotate(314deg) brightness(101%) contrast(85%);
                            }
                        }
                        span {
                            &:first-child {
                                width: 100%;
                                font-weight: bold;
                                display: block;
                            }
                        }
                        svg {
                            position: absolute;
                            right: 15px;
                            width: 30px;
                            height: 30px;
                            transform: rotate(90deg);
                            top: 24px;
                            filter: invert(62%) sepia(0%) saturate(1%) hue-rotate(243deg) brightness(86%) contrast(90%);
                        }
                    }
                    
                    .textarea-container {
                        width: 70%;
                    }
                    label {
                        color: #000 !important;
                        display: inline-block;
                        margin-bottom: 5px;
                        width: 30%;
                        text-align: left;
                        font-size: 13px;
                    }
                    
                    input {
                        display: inline-block;
                        width: 70%;
                        padding: 10px 15px;
                        border: none;
                        border-radius: 24px;
                        &:disabled {
                            background-color: #FFF;
                            color: #000;
                        }
                    }
                }
            }
        }
        .right_side {
            flex-basis: 50%;
            .photos {
                padding: 15px 20px;
                .photo_block {
                    margin-bottom: 20px;
                    span {
                        width: 100%;
                        display: block;
                        margin-bottom: 5px;
                    }
                    .photo {
                        display: flex;
                        justify-content: left;
                        align-items: center;
                        img {
                            border-radius: 50%;
                            width: 160px;
                            height: 160px;
                            max-width: 100%;
                            max-height: 100%;
                            object-fit: contain;
                            border-radius: 50%;
                        }
                    }
                }
            }
            .form {
                padding: 15px 30px 15px;
                display: flex;
                font-size: 13px;
                width: 100%;
                .left-part,
                .right-part {
                    width: 50%;
                    div {
                        min-height: 50px;
                        text-align: left;
                    }
                }

                .left-part {
                    padding-top: 10px;
                    text-align: left;
                }

                .right-part {
                    div:has(> i), div:has(> span) {
                        padding-top: 10px;
                    }
                }

                .button {
                    margin-right: 10px;
                    padding: 10px 20px;
                    border: none;
                    width: 150px;
                    background-color: #8CFF79;
                    border-radius: 24px;
                    &:hover {
                        background-color: #7fe86d;
                        cursor: pointer;
                    }
                    &.disabled {
                        pointer-events: none;
                        background-color: red;
                    }
                }

                .checkbox {
                    margin-right: 5px;
                }
            }
            .tasks {
                padding: 10px 20px;
                border-radius: 24px;
                background-color: #FFF;
                width: calc(100% - 60px);
                margin-left: 30px;
                ul {
                    margin-top: 15px;
                    li {
                        margin-top: 10px;
                        i {

                        }
                        span {
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
        .tasks_container {
            flex-basis: 20%;
            padding: 0 10px;
            height: 100%;

            .tasks_list {
                width: 100%;
                margin-top: 20px;
                .tasks {
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 15px;
                    .task_block, > a {
                        &.wider1 { flex: 0 0 30%; }
                        &.wider2 { flex: 0 0 15%; }
                        &.wider3 { flex: 0 0 20%; }
                        flex: 1;
                        background-color: #FFF;
                        padding: 10px 20px;
                        position: relative;
                        text-align: left;
                        margin: 0 5px;
                        margin-bottom: 10px;
                        display: flex;
                        flex-direction: column;
                        border-radius: 24px;
                        justify-content: center;
                        justify-items: center;
                        text-align: center;
                        span {
                            &:first-child {
                                order: 1;
                            }
                            &:last-child {
                                order: 2;
                                font-weight: 100;
                            }
                        }
                        &:last-child {
                            flex: 0 0 15%;
                            text-align: center;
                            background-color: #82b4ff;
                            justify-content: center;
                            .task_link {
                                margin: auto;
                            }
                            &:has(.task_link) {
                                &:hover {
                                    cursor: pointer;
                                    opacity: .8;
                                }
                            }
                        }
                        &:last-child {
                            span {
                                background-color: #EEE;
                                padding: 2px 3px;
                                border-radius: 8px;
                                color: #000;
                                text-shadow: none;
                            }
                        }
                        
                        &.days-good {
                            background-color: #2ecc71;
                        }
                        &.days-normal {
                            background-color: #9ee7bc;
                        }
                        &.days-warning {
                            background-color: #f1c40f;
                        }
                        &.days-error {
                            background-color: #e74c3c;
                        }
                        &.days-hide {
                            background-color: #EEE;
                        }
                    }
                    > a {
                        .task_block {
                            padding: 0;
                            margin-bottom: 0px;
                        }
                        color: #FFF;
                        text-shadow: 0px 0px 2px rgba(0,0,0,.3);
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

.default_button {
    border: none;
    padding: 10px 20px;
    border-radius: 24px;
    background-color: white;
    font-weight: bold;
    margin-bottom: 10px;
    &:hover {
        background-color: #EEE;
        cursor: pointer;
    }
    &:active {
        background-color: #CCC;
    }
}

.program_title {
    color: #a29bfe !important;
    b {
        font-weight: 100;
        color: #888 !important;
    }
}

.info_title {
    color: #636e72 !important;
}

.button-delete {
    font-size: 12px;
    margin-left: 10px;
    font-weight: 100;
    color: #666;
    cursor: pointer;
    padding: 2px 4px;
    background-color: #eee;
    border-radius: 4px;
    vertical-align: top;
    opacity: 0;
    &:hover {
        color: #999;
    }
}

.modal_task {
    width: 100%;
    height: 100%;
    .content {
        width: 100%;
        height: calc(100% - 50px);
        text-align: center;
        padding: 20px;
        span {
            width: 100%;
        }
        select {
            height: 30px;
            padding: 5px 10px;
            border-radius: 16px;
            border: 1px solid #EEE;
            width: 100%;
            margin-top: 10px;
        }
        ul {
            text-align: left;
            font-weight: 100;
            li {
                .li_item {
                    align-items: center;
                    padding: 5px 10px;
                    button {
                        border: none;
                        border-radius: 50%;
                        font-size: 18px;
                        &:hover {
                            background-color: #CCC;
                            cursor: pointer;
                        }
                    }
                    &:hover {
                        background-color: #EEE;
                    }
                    margin-top: 10px;
                    display: flex;
                    justify-content: space-between;
                }
            }
            margin-bottom: 40px;
        }
    }
    .buttons {
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: end;
        gap: 15px;
        width: 100%;
        padding: 15px;
        > button, > a > button {
            padding: 5px 10px;
            border-radius: 10px;
            border: none;
            background-color: #97edba;
            &:last-child {
                background-color: #EEE;
            }
            &:hover {
                cursor: pointer;
                background-color: #66cb8f;
                &:last-child {
                    background-color: #d1cece;
                }
            }
        }
    }
}

.table {
    th {
        border: 1px solid #EEE;
    }

    td {
        font-weight: 100;
        padding: 3px 5px;
    }
}
.table-epxand {
    th {
        background: #EEE;
    }

    td {
        background: #EEE;
    }
}