.container {
	display:flex;
	flex:1;
	flex-direction:row;
	width:100%;
	min-width:1140px;
	overflow:hidden;
	/* background:linear-gradient(180deg, #f0f2fd 0%, #fff8f9 100%); */
	background: #FFF;
}
.timesheet-error {
	color: #c0392b;
}
.container .menu {
	width:240px;
	background: #FFF;
}
.container .menu .logo {
	width:100%;
	padding:30px 20px 10px 30px;
	display:flex;
	justify-content:center;
	align-items:center;
}
.container .menu .logo img {
	width:100%;
	transition: width .1s linear, height .1s linear;
}
.container .menu .logo.logo-collapsed {
	width: 40px;
	height: 40px;
	transition: width .1s linear, height .1s linear;
}
.container .menu .logo.logo-collapsed img {
	width: 40px;
	height: 40px;
}
.container .menu ul {
	margin:5px 0 0;
}
.container .menu ul li {
	margin:0 0 0px 10px;
}
.container .menu ul li a {
	display:flex;
	flex-direction:row;
	align-items:center;
	text-decoration:none;
	color:#33333e;
	font-size:13px;
	transition:all .5s ease-in;
	padding:7px 0 5px 20px;
}
.container .menu ul li a:hover, .container .menu ul li a.selected:hover {
	color:#4a495e;
}
.container .menu ul li a:hover, .container .menu ul li a.selected i {
	color: #5f27cd;
}
.container .menu ul li i {
	position:relative;
	margin-right:15px;
	font-size:110%;
	display: none;
}
.container .menu ul li i.bi-chevron-down, .container .menu ul li i.bi-chevron-right {
	font-size:100%;
	margin-left:auto;
}
.container .menu ul li a.selected {
	//background:#605dff10;
	//border-radius:100px;
	font-weight: bold;
	color:#393941;
	text-decoration: underline;
}
.container .menu ul li i .notification-new {
	position:absolute;
	top:1px;
	right:-4px;
	width:12px;
	height:12px;
	border-radius:20px;
	background:#c00;
	border: 2px solid #f4f3ef;
}
.container .menu ul li ul {
	margin:5px 0 5px 5px;
}
.container .menu ul li ul li a {
	padding:2px 10px;
}
.container .menu ul li ul {
  	margin: 5px 0 5px 5px;
}

.container .menu ul li ul li {
  	position: relative;
	padding-left: 10px;
}
.container .menu ul li ul li::before {
	content: "";
	display: block;
	position: absolute;
	left: 5px;
	top: 50%;
	transform: translateY(-50%);
	width: 4px;
	height: 4px;
	background-color: #888;
	border-radius: 50%;
}

.container .menu ul li ul li a {
  padding: 2px 10px;
}
.container .menu ul li ul li a.selectedinner {
	color:#3b3b4d;
	text-decoration: underline;
	font-weight: bold;
}
.container .main {
	flex:1;
	padding:15px;
	padding-top:0;
	width: calc(100% - 240px);
}
.container.menu-collapsed .main {
	width: calc(100% - 60px);
}

.main-search {
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
	height: 40px;
	position: relative;
}

.main-search .search-input input {
	height: 40px;
	border-radius: 24px;
	border: 1px solid #F8F8F8;
	padding: 10px 20px;
	width: 100%;
}

.main-search .search-input {
	position: relative;
	width: 100%;
}

.main-search .search-input > i {
	position: absolute;
	right: 15px;
	top: 4px;
	cursor: pointer;
	font-size: 22px;
	color: #888;
}

.main-search .main-user-info {
	cursor: pointer;
	width: 35%;
	margin-left: 10px;
	border-radius: 24px;
	padding: 10px 20px;
	border: 1px solid #F8F8F8;
	background-color: #F8F8F8;
	position: relative;
	user-select: none;
}

.rotate {
	transform: rotate(180deg);
}

.without-rotate {
	transform: rotate(90deg);
}

.main-search .main-user-info svg {
	position: absolute;
	right: 20px;
	transition: transform .1s linear;
}

.main-user-info-dropdown {
	position: absolute;
	top: 50px;
	width: 200px;
	background-color: #F8F8F8;
	box-shadow: 0 0 5px rgba(0,0,0,.3);
	padding: 10px 15px;
	border-radius: 16px;
}

.main-user-info-dropdown a {
	color: #292968;
}

.main-user-info-dropdown a i {
	margin-right: 5px;
}

.main-user-info-dropdown a:hover {
	color: #5a5a70
}

.container .main .main-container {
	margin:20px 0 0;
	padding:10px 15px 10px;
	/* background:linear-gradient(180deg,rgba(255,255,255,.4) 0%, rgba(255,255,255,.4) 100%); */
	border-radius:20px;
}
.container .main .main-container .main-scroll {
	position:relative;
	height:calc(100vh - 200px);
	margin-top:10px;
	padding-top:10px;
	overflow:auto;
}
.container .main .title {
	display:flex;
	flex-direction:row;
	align-items:center;
	//justify-content:space-between;
	height:50px;
}
.container .main .title .title-block {
	display:flex;
	flex-direction:row;
	align-items:center;
}
.container .main .title .title-block a.back {
	margin:2px 5px 0 0;
	font-size:130%;
}
.container .main .title .title-block h1 {
	font-size:150%;
	font-weight:600;
	color:#292968;
}
.container.menu-collapsed .menu {
	width: 60px;
	transition: width 0.3s ease-in;
	padding-top: 15px;
	overflow: visible;
}

.container.menu-collapsed .menu ul {
	margin-top: 15px;
}

.container.menu-collapsed .menu ul li a {
	display: flex;
	justify-content: center;
	padding-left: 0;
}

.container.menu-collapsed .menu ul li i {
	display: block;
	font-size: 20px;
}

.tooltip {
	position: absolute;
	bottom: 5px;
	font-weight: 100;
	font-size: 12px;
	left: 25px;
	background-color: #5f27cd;
	color: #fff;
	padding: 0px 5px;
	border-radius: 4px;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.2s ease, visibility 0.2s ease;
	white-space: nowrap;
	z-index: 1;
}

i:hover .tooltip {
	opacity: 1;
	visibility: visible;
}