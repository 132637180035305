.correction-export-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}

.correction-export-row > div {
  width: 100%;
}

.correction-export-row .form-row {
  width: 100%;
}
.correction-export-row .form-row select {
  width: 100%;
}
