.correction-function h4 {
	color:#70708c;
    font-size:120%;
    margin-left:5px;
}
.correction-function .date {
	color:#292968;
	font-weight:600;
	margin-left:5px;
}
.list-function {
	padding-top:10px;
	margin:0 20px 0 10px;
}
.list-function-item {
	display:flex;
	flex-direction: column;
	align-items: flex-start;
	padding:10px;
	transition:all .5s linear;
	border-bottom:1px solid #eee;
}
.list-function-item:hover {
	background:#f8f8f8;
}
.list-function-item .name {
	color:#70708c;
}
.list-function-item .name span {
	display:block;
	font-weight:600;
	font-size:120%;
	color:#292968;
}