.notfound {
	margin-left:10px;
}
.notfound h4 {
	color:#292968;
	margin-bottom:20px;
}
.notfound p {
	color:#70708c;
}
.notfound a {
	display:inline-block;
	padding:10px 30px;
	margin:40px auto 0;
	border-radius:100px;
    background:#605bff;
	color:#fff;
	transition:.3s;
	text-decoration:none;
}
.notfound a:hover {
	cursor:pointer;
	background:#2e00d6;
}