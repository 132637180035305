$primary-green      : #2ecc71;
$primary-green-dark : #27ae60;
$primary-red        : #e74c3c;
$primary-red-dark   : #c0392b;
$primary-bggray     : #f6f6f6;
$primary-purple     : #a29bfe;
$primary-darkpurple : #574b90;
$primary-white      : #FFF;
$border             : 24px;
$border-round       : 50%;
$border-little      : 16px;
$default-padding    : 5px;
.table {
	width: 100%;
    border-radius: 16px;
    margin-top: 20px;
	margin-bottom: 0px;
	border: 1px solid #dddddd;
	border-collapse: collapse; 
}
.table th {
	font-weight: bold;
	padding: 5px;
	background: #efefef;
	border: 1px solid #dddddd;
}
.table td {
	border: 1px solid #dddddd;
	padding: 5px;
}
.section-block-history {
    display: inline-block;
    width: 100%;
    button {
        padding: 5px 10px;
        border-radius: 14px;
        background-color: #EEE;
        border: 1px solid #CCC;
        &:hover {
            cursor: pointer;
            background-color: #CCC;
        }
        margin: 10px;
    }
    .history-container {
        padding: 15px 10px;
        background-color: #FFF;
        border-radius: 16px;
        margin: 10px;
        span {
            
        }
    }
}

.programs-container {
    font-size: 13px;
    .programs-list {
        .program-item {
            background-color: $primary-bggray;
            border-radius: $border;
            padding: $default-padding;
            display: flex;
            align-items: center;
            flex-direction: column;
            &:not(&:first-child) {
                margin-top: 10px;
            }
            .main-section {
                position: relative;
                display: flex;
                flex: 1;
                gap: 10px;
                width: 100%;
                visibility: visible;
                height: auto;
                transition: max-height .3s linear, margin-top .3s linear;
                &:has(.block-toggle) { padding-right: 40px; }
                &.invisible { margin-top: 0px; max-height: 0; overflow: hidden; }
                &.visible { margin-top: 10px; max-height: 600px; overflow: scroll; }
                .block-toggle {
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                    i {
                        display: block;
                        font-size: 20px;
                        color: #636e72;
                        transform: rotate(0deg);
                        transition: transform .3s linear;
                    }
                    &:hover {
                        cursor: pointer;
                        opacity: .7;
                        text-shadow: 0 0 2px #EEE;
                    }
                }
                .block-toggle.active i { transform: rotate(180deg); }
                justify-content: space-between;
                .section-block, .section-blocks .block {
                    button {
                        border: none;
                        border-radius: $border;
                        width: 100%;
                        height: 100%;
                        background-color: #EEE;
                        &:hover {
                            cursor: pointer;
                            background-color: #DDD;
                        }
                    }
                    &.tasks-done, &.tasks-not-started, &.warning, &.default, &.tasks-error {
                        flex-basis: auto;
                        background-color: #2ecc71;
                        color: #FFF;
                        position: relative;
                        width: 250px;
                        text-shadow: 0 0 2px rgba(0,0,0,.3);
                    }
                    &.tasks-done {
                        background-color: #2ecc71;
                        color: #FFF;
                    }
                    &.tasks-not-started {
                        flex-basis: content;
                        background-color: #FFF;
                        color: #000;
                        text-shadow: none;
                    }
                    &.warning {
                        background-color: #f1c40f;
                        color: #FFF;
                    }
                    &.tasks-error {
                        background-color: #e74c3c;
                        color: #FFF;
                    }
                    &.default {
                        background-color: #FFF;
                        color: #000;
                        text-shadow: none;
                        flex-basis: content;
                    }
                    display: flex;
                    flex-basis: content;
                    &:has(.block-date) { flex-basis: 150px; }
                    &:first-child { flex-basis: 40%; }
                    background-color: $primary-white;
                    border-radius: $border;
                    padding: 5px;
                    flex-direction: row;
                    .user-avatar {
                        width: 38px;
                        height: 38px;
                        border-radius: $border-round;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: $border-round;
                        }
                    }
                    &:has(.task-list) { flex-basis: calc(40% - 15px); }
                    .block-info {
                        padding: 0 10px;
                        display: flex;
                        flex-direction: column;
                        .name1 { font-weight: bold; }
                        .name2 { &.program-name { color: $primary-purple; b { color: #BBB; font-weight: 100 } } }
                        .task-list {
                            li {
                                &:hover {
                                    border-radius: $border-little;
                                    background-color: $primary-bggray;
                                }
                                padding: 0 10px;
                                cursor: pointer;
                                &.task-false { color: $primary-red; }
                                &.task-true { color: $primary-green; }
                                i { margin-right: 5px; }
                            }
                        }
                        .links {
                            a {
                                text-decoration: none;
                                color: #574b90;
                            }
                            li {
                                cursor: pointer;
                                &:hover {
                                    color: #444;
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                    .days-count {
                        width: 38px;
                        height: 38px;
                        font-size: 20px;
                        text-align: center;
                        line-height: 38px;
                    }
                }
            }
        }
    }
}

.task-done-btn {
    position: absolute;
    font-size: 24px;
    right: 10px;
    top: 9px;
    &:hover {
        color: #EEE;
        cursor: pointer;
    }
}

.programs-filter {
    width: 100%;
    background-color: $primary-bggray;
    border-radius: $border;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    font-size: 12px;
    padding: 10px 10px;
    .tab {
        width: auto;
        &:hover {
            cursor: pointer;
            opacity: 0.8;
        }
    }
    > div {
        &.active {
            background-color: #82E1FF;
        }
        .center-text {
            text-align: center;
        }
        padding: 5px 10px;
        background-color: #FFF;
        display: block;
        border-radius: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
        &:last-child {
            margin-right: 0;
        }
        input {
            border: none;
            padding-left: 5px;
        }
        select {
            border: none;
            border-radius: 16px;
            background-color: #f6f6f6;
            padding: 2px 4px;
        }
    }
}

.programsList {
    background-color: $primary-bggray;
    padding: 10 15px;
    padding: 10px 15px;
    border-radius: 16px;
    width: 100%;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    > div {
        background-color: #FFF;
        padding: 10px 15px;
        font-weight: bold;
        border-radius: 16px;
        position: relative;
        i {
            font-style: normal;
            font-weight: normal;
        }
        span {
            display: block;
        }
        ul {
            counter-reset: my-counter;
            list-style-type: none;
            li {
                font-weight: normal;
                &:before {
                    counter-increment: my-counter;
                    content: counter(my-counter) ". ";
                }
            }
        }
        padding-bottom: 65px;
        button {
            a {
                color: white;
                text-decoration: none;
            }
            border-radius: 16px;
            padding: 10px 0px;
            border: none;
            background-color: #eccc68;
            font-weight: bold;
            display: block;
            margin-left: auto;
            color: white;
            text-shadow: 0px 0px 3px rgba(0,0,0,1);
            position: absolute;
            bottom: 10px;
            left: 10px;
            width: calc(50% - 15px);
            &:hover {
                background-color: #d2b45b;
                cursor: pointer;
            }
            &:first-child {
                
            }
            &:last-child {
                &:hover {
                    background-color: #27ae60;
                }
                right: 10px;
                background-color: #2ecc71;
            }
        }
        &.current-program {
            background-color: #F8EFBA;
            button {
                &:last-child {
                    background-color: #CCC;
                }
            }
        }
    }
}

.programs-item-list {
    margin-bottom: 10px;
    &:hover {
        opacity: .8;
        box-shadow: 0px 0px 5px rgba(0,0,0, .3);
    }
}

.programs-list-container {
    width: 100%;
    font-size: 12px;
    .programs-list {
        width: 100%;
        a {
            text-decoration: none;
            &:active, &:hover, &:focus {
                text-decoration: none;
            }
            color: #000;
        }
        .programs-item-list {
            margin-bottom: 10px;
            &:hover {
                opacity: .8;
                box-shadow: 0px 0px 5px rgba(0,0,0, .3);
            }
        }
            
        .programsList {
            background-color: #EEE;
            padding: 10 15px;
            margin-top: -10px;
            padding: 10px 15px;
            border-radius: 16px;
            width: 100%;
            margin-bottom: 10px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 10px;
            > div {
                background-color: #FFF;
                padding: 10px 15px;
                font-weight: bold;
                border-radius: 16px;
                position: relative;
                i {
                    font-style: normal;
                    font-weight: normal;
                }
                span {
                    display: block;
                }
                ul {
                    counter-reset: my-counter;
                    list-style-type: none;
                    li {
                        font-weight: normal;
                        &:before {
                            counter-increment: my-counter;
                            content: counter(my-counter) ". ";
                        }
                    }
                }
                padding-bottom: 65px;
                button {
                    a {
                        color: white;
                    }
                    border-radius: 16px;
                    padding: 10px 20px;
                    border: none;
                    background-color: #eccc68;
                    font-weight: bold;
                    display: block;
                    margin-left: auto;
                    color: white;
                    text-shadow: 0px 0px 3px rgba(0,0,0,1);
                    position: absolute;
                    bottom: 10px;
                    left: 10px;
                    width: calc(50% - 15px);
                    &:hover {
                        background-color: #d2b45b;
                        cursor: pointer;
                    }
                    &:first-child {
                        
                    }
                    &:last-child {
                        &:hover {
                            background-color: #27ae60;
                        }
                        right: 10px;
                        background-color: #2ecc71;
                    }
                }
                &.current-program {
                    background-color: #F8EFBA;
                    button {
                        &:last-child {
                            background-color: #CCC;
                        }
                    }
                }
            }
        }

        .program {
            border-radius: 24px;
            padding: 0px 5px 5px;
            background-color: #EEE;
            display: flex;
            margin-bottom: 15px;

            > div {
                margin: 5px 10px;
                &:has(.block-date) {
                    flex-basis: 130px;
                }
                &:has(.avatar) {
                    flex-basis: 40%;
                }
                .programs-block, .programs-tasks, .programs-tasks a {
                    margin-top: 5px;
                    background-color: #FFF;
                    border-radius: 24px;
                    padding: 5px 15px 7px;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: left;
                    &.block-date {
                        text-align: center;
                    }
                    .links {
                        li {
                            cursor: pointer;
                            &:hover {
                                color: #444;
                                text-decoration: underline;
                            }
                        }
                    }
                    &:has(> button) {
                        padding: 0;
                        height: 48px;
                        background-color: #95afc0;
                    }
                    > button {
                        border: none;
                        text-shadow: 0px 0px 3px rgba(0,0,0,1);
                        font-weight: bold;
                        color: white;
                        border-radius: 16px;
                        background-color: transparent;
                        padding: 13px 15px;
                        height: 100%;
                        &:hover {
                            cursor: pointer;
                        }
                        &:active {
                            opacity: .8;
                        }
                    }
                    &.tasks-done, &.tasks-not-started, &.warning, &.default, &.tasks-error {
                        background-color: #2ecc71;
                        color: #FFF;
                        position: relative;
                        width: 250px;
                        padding-right: 20px;
                        margin: 5px auto;
                        text-shadow: 0 0 2px rgba(0,0,0,.3);
                        > div {
                            margin-top: -3px;
                            font-size: 30px;
                            font-weight: bold;
                            position: absolute;
                            text-align: center;
                            width: 30px;
                        }
                        span {
                            margin-left: 40px;
                        }
                    }
                    &.tasks-done {
                        background-color: #2ecc71;
                        color: #FFF;
                    }
                    &.tasks-not-started {
                        background-color: #FFF;
                        color: #000;
                        text-shadow: none;
                    }
                    &.warning {
                        background-color: #f1c40f;
                        color: #FFF;
                    }
                    &.tasks-error {
                        background-color: #e74c3c;
                        color: #FFF;
                    }
                    &.default {
                        background-color: #FFF;
                        color: #000;
                        text-shadow: none;
                    }
                    ul {
                        li {
                            color: #34495e;
                            &.active {
                                text-decoration: line-through;
                                .bi {
                                    color: #2ecc71;
                                }
                            }
                            > .bi {
                                text-shadow: 0 0 2px rgba(0,0,0,.3);
                                &:hover {
                                    cursor: pointer;
                                }
                                margin-right: 10px;
                            }
                            font-weight: bold;
                            margin-top: 10px;
                        }
                    }
                    .avatar {
                        position: absolute;
                        left: 5px;
                    }
                    span {
                        &:first-child {
                            font-weight: bold;
                        }
                        &:last-child {
                            
                        }
                    }
                }
                .always-visible {
                    display: flex !important;
                }
          
                .hidden {
                    display: none;
                }
            }
        }
        .programs-item {
            width: 100%;
            background-color: #EFEFEF;
            padding: 10px 0px;
            border-radius: 24px;
            display: flex;
            justify-content: space-between;
            .item-block {
                background-color: #FFF;
                width: 40%;
                border-radius: 16px;
                padding: 15px 20px;
                margin: 0px 10px;
                &:first-child {
                    width: 30%;
                }
                span {
                    width: 100%;
                    display: block;
                    &:first-child {
                        margin-bottom: 10px;
                        font-weight: bold;
                    }
                }
                ul {
                    counter-reset: my-counter;
                    list-style-type: none;
                    li {
                        &:before {
                            counter-increment: my-counter;
                            content: counter(my-counter) ". ";
                        }
                    }
                }
                input {
                    border-radius: 16px;
                    padding: 10px 20px;
                    border: 1px solid #EEE;
                    width: 100%;
                }
                button {
                    background-color: #EEE;
                    border-radius: 16px;
                    border: 1px solid #EEE;
                    margin-left: auto;
                    margin-left: 10px;
                }
                .tasks {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    button {
                        padding: 10px 20px;
                    }
                }
                .tasks-list {
                    padding: 10px 15px;
                    width: 100%;
                    li {
                        margin-bottom: 5px;
                        width: 100%;
                        display: block;
                        button {
                            cursor: pointer;
                            &:hover {
                                background-color: #CCC;
                            }
                        }
                    }
                    input {
                        padding: 5px 10px;
                        margin-bottom: 5px;
                        width: 70%;
                        display: inline-block;
                    }
                }
                &:last-child {
                    width: 30%;
                    input {
                        width: 100px;
                        font-size: 16px;
                        padding: 8px 18px;
                        margin-right: 5px;
                    }
                }
            }
        }
        .footer {
            width: 100%;
            text-align: right;
            margin-top: 20px;
            button {
                border-radius: 16px;
                padding: 10px 20px;
                border: none;
                background-color: #8CFF79;
                font-weight: bold;
                &:hover {
                    background-color: #7fe86d;
                    cursor: pointer;
                }
            }
        }
    }
}


.program_title {
    color: #a29bfe !important;
    b {
        color: #c6c6c6;
        font-weight: 100;
    }
}

.info_title {
    color: #636e72 !important;
}