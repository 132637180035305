.login-container {
	position:relative;
	width:420px;
	margin:60px auto 0;
	padding:50px;
	background:#fff;
	border-radius:5px;
}
.login-container img {
	height:140px;
	margin:10px auto 60px;
	display:block;
}
.login-container .form-column {
	display:block;
	margin:10px 0 20px;
}
.login-container .form-column span {
	margin:0 0 5px 5px;
	display:block;
	color:#70708c;
	text-transform:lowercase;
}
.login-container .form-column label:hover input {
	border-color:#999;
}
.login-container .form-column input {
	padding:15px;
	width:100%;
	border-radius:4px;
	border:1px solid #e0e7ee;
	font-size:100%;
	font-weight:600;
	background:#f7f7f7;
}
.login-container .form-column input[iserror=true],
.login-container .form-column label:hover input[iserror=true],
.login-container .form-column label select[iserror=true],
.login-container .form-column label:hover select[iserror=true] {
	border-color:#ff402b;
	background-color:#fff9f8;
	background:url(../../Images/icons/attention.svg) 99% 0 no-repeat;
}
.login-container button.btn {
	border-radius:6px;
    background:#605bff;
	color:#fff;
	transition:.3s;
	text-transform:uppercase;
	border:none;
	padding:18px 0;
	width:100%;
	margin-top:20px;
}
.login-container button.btn:hover {
	cursor:pointer;
	background:#2e00d6;
}
.login-container .form-column select {
	width:100%;
    padding:10px;
	border:1px solid #e0e7ee;
    border-radius:4px;
	font-size:100%;
	font-weight:600;
	background:#f7f7f7;
}
.login-container .form-column select:hover {
	border-color:#999;
}